// TODO: Faizan/Hardik - the config maps are not consistent in structure: label, value, key, icon, class  (if the value in DB and the Key is same then we should use only the value, key was used for the cases when the value in DB was not in all UPPER CASE) (this is to be fixed in all portal in a branch)

export const BID_SUBMISSION_STATUS = {
  PARTIALLY_SUBMITTED: {
    label: 'In-Progress',
    value: 'PARTIALLY_SUBMITTED'
  },
  PENDING: { label: 'New', value: 'PENDING' },
  SUBMITTED: { label: 'Submitted', value: 'SUBMITTED' }
};

export const BID_ITEM_STATUS = {
  ACCEPTED: { className: 'bg-green', label: 'Available', value: 'ACCEPTED' },
  PENDING: { className: 'bg-dim-gray', label: 'Pending', value: 'PENDING' },
  REJECTED: {
    className: 'bg-error-red',
    label: 'Not Available',
    value: 'REJECTED'
  }
};

export const HOST_INTERACTION_STATUS = {
  INQUIRED: {
    className: 'in-progress',
    label: 'Inquired',
    value: 'INQUIRED'
  },
  NEW: { className: 'new', label: 'New', value: 'NEW' },
  REPEAT: { className: 'submitted', label: 'Repeat', value: 'REPEAT' }
};

export const CART_ITEM_STATUS = {
  ACCEPTED: { value: 'ACCEPTED' },
  DECLINED: { value: 'DECLINED' },
  ORDERED: { value: 'ORDERED' },
  REQUESTED: { value: 'REQUESTED' },
  REQUEST_PENDING: { value: 'REQUEST_PENDING' }
};

export const ZENDESK_PRIORITY = {
  HIGH: {
    className: 'submitted rounded-lg border border-green-base',
    icon: 'green-status-icon.svg',
    value: 'High'
  },
  LOW: {
    className: 'expired rounded-lg border border-error-red',
    icon: 'red-status-icon.svg',
    value: 'Low'
  },
  NORMAL: {
    className: 'in-progress rounded-lg border border-warning-dark',
    icon: 'yellow-status-icon.svg',
    value: 'Normal'
  },
  URGENT: {
    className: 'new rounded-lg border border-info-base',
    icon: 'blue-status-icon.svg',
    value: 'Urgent'
  }
};

export const CART_STATUS = {
  ABANDON: { value: 'Abandon' },
  APPROVE: { value: 'Approve' },
  COLLABORATE: {
    className: 'bg-greenlight text-green',
    label: 'Collaborate',
    value: 'Collaborate'
  },
  DISCARD: { value: 'Discard' },
  DRAFT: {
    className: 'bg-gray text-dim-gray',
    label: 'Draft',
    value: 'Draft'
  },
  ORDER: {
    className: 'bg-light-red text-brand',
    label: 'Order',
    value: 'Order'
  },
  QUOTE: { value: 'Quote' },
  REJECT: { value: 'Reject' },
  REVISE: { value: 'Revise' }
};

export const ORDER_STATUS = {
  CANCELLED: {
    class: 'expired',
    label: 'Cancelled',
    value: 'CANCELLED'
  },
  COMPLETED_NOT_SETTLED: {
    class: 'in-progress',
    label: 'Completed: Not Settled',
    value: 'COMPLETED_NOT_SETTLED'
  },
  COMPLETED_SETTLED: {
    class: 'submitted',
    label: 'Completed: Settled',
    value: 'COMPLETED_SETTLED'
  },
  DISPUTED: {
    class: 'expired',
    label: 'Disputed',
    value: 'DISPUTED'
  },
  ORDER_CONFIRMED: {
    class: 'submitted',
    label: 'Order Confirmed',
    value: 'ORDER_CONFIRMED'
  },
  ORDER_INITIATED: {
    class: 'bg-neutral text-dim-gray',
    label: 'Order Initiated',
    value: 'ORDER_INITIATED'
  },
  ORDER_PLACED: {
    class: 'new',
    label: 'Order Placed',
    value: 'ORDER_PLACED'
  },
  PARTNER_BOOKED: {
    class: 'submitted',
    label: 'Partner Booked',
    value: 'PARTNER_BOOKED'
  },
  POSTPONED: {
    class: 'in-progress',
    label: 'Postponed',
    value: 'POSTPONED'
  },
  READY_FOR_EXECUTION: {
    class: 'submitted',
    label: 'Ready for Execution',
    value: 'READY_FOR_EXECUTION'
  },
  SETTLED_AND_CLOSED: {
    class: 'submitted',
    label: 'Settled and Closed',
    value: 'SETTLED_AND_CLOSED'
  },
  SUSPENDED: {
    class: 'expired',
    label: 'Suspended',
    value: 'SUSPENDED'
  }
};

export const LINE_ITEM_SOURCE = {
  CART: {
    value: 'CART'
  },
  ORDER: {
    value: 'ORDER'
  }
};

export const DEFAULT_PROGRESSBAR_PARAMETERS = {
  color: '#ff794d',
  height: 8,
  startPosition: 0.1,
  stopDelayMs: 100
};

export const DEVICE_SURFACE = {
  MOBILE: 'mobile',
  WEB: 'web'
};

export const HAFLA_BANK_DETAILS = [
  { label: 'Account No', value: '019100732211' },
  { label: 'Bank', value: 'Mashreq Bank' },
  { label: 'IBAN', value: 'AE460330000019100732211' },
  { label: 'Name', value: 'Evinops Limited' },
  { label: 'Swift Code', value: 'BOMLAEAD' }
];

export const HAFLA_REGISTRATION_DETAILS = {
  companyName: 'Evinops Limited (Hafla)',
  formattedAddress: 'Abu Dhabi, UAE PO Box: 46617',
  trnNumber: '100475504500003'
};

export const IMAGEKIT_DAM_ROOT_DIRECTORY = `/API/${process.env.NEXT_PUBLIC_APP_ENV}`;

export const NAMED_QUERY_PARAMS = {
  ANYBODY_CAN_PAY_RAW: 'anybody-can-pay',
  ORDER_ID: 'order-id',
  PAYMENT_FLOW_SOURCE: 'payment-flow-source',
  PAYMENT_ID: 'payment-id',
  PAYMENT_RECEIPT_STATUS: 'payment-receipt-status',
  PAYMENT_SOURCE_TYPE: 'reference-type',
  REFERENCE_ID: 'reference-id',
  TRANSACTION_AMOUNT: 'transaction-amount',
  USER_ID: 'user-id'
};

export const MEDIA_SOURCE = {
  AWS_S3: 'AWS_S3',
  CODE_REPO: 'CODE_REPO',
  IMAGE_KIT: 'IMAGE_KIT',
  LOCALHOST: 'LOCALHOST'
};

export const mediaStoreBaseURL =
  process.env.STORYBOOK_PUBLIC_MEDIA_STORE_URL ||
  process.env.NEXT_PUBLIC_MEDIA_STORE_URL ||
  '';

export const PRODUCT_STATUS = {
  DRAFT: 'Draft',
  PUBLISHED: 'Published',
  UNPUBLISHED: 'Unpublished'
};

export const PAYMENT_FLOW_SOURCE = {
  ANYBODY_CART: 'ANYBODY_CART',
  ANYBODY_ORDER: 'ANYBODY_ORDER',
  HOST_CART_DEFAULT: 'HOST_CART_DEFAULT',
  HOST_CART_NON_DEFAULT: 'HOST_CART_NON_DEFAULT',
  PLANNER_CART: 'PLANNER_CART',
  HOST_ORDER: 'HOST_ORDER',
  PLANNER_ORDER: 'PLANNER_ORDER'
};

export const PAYMENT_SOURCE_TYPE = {
  ORDER: 'order',
  CART: 'cart'
};

export const PRODUCT_TYPE = {
  QUOTE: 'Quote',
  TICKET: 'Ticket',
  CART: 'Cart'
};

export const INVOICE_QUOTE_PDF_TYPE = {
  INVOICE: 'INVOICE',
  QUOTE: 'QUOTE'
};

export const UNAVAILABLE_CART_ITEM_ALERT =
  'We apologize! One or more products in your cart are currently unavailable. Please remove those products from the cart to checkout.';

export const STATIC_MEDIA_STORE_PATH_STUB = '/static_images/planner';

export const staticMediaStoreBaseURL = `${mediaStoreBaseURL}${STATIC_MEDIA_STORE_PATH_STUB}`;

export const VAT_PERCENTAGE = 5;

export const ZENDESK_CONFIG = {
  agentTicketViewURL: 'https://hafla4229.zendesk.com/agent/tickets/'
};

export const CART_LIST_TYPE = {
  EVENT: 'EventCartList',
  ORDER: 'OrderedCartList',
  ARCHIVE: 'ArchivedCartList'
};

export const NO_PRODUCT_IMAGE = {
  imageTitle: '',
  sortOrder: 1,
  source: MEDIA_SOURCE.AWS_S3,
  url: '/noProductImage.png'
};

export const TAG_OPTIONS_SELECTION_MODE = {
  MULTIPLE: 'MULTIPLE',
  SINGLE: 'SINGLE'
};

export const VERIFICATION_STATUSES = {
  FAILED: 'FAILED',
  UNCHECKED: 'UNCHECKED',
  VERIFIED: 'VERIFIED'
};

export const ACTION_MODAL_SOURCE = {
  ADD_ITEM_TO_CART: 'ADD_ITEM_TO_CART',
  EXPORT_CART: 'EXPORT_CART',
  IMPORT_CART: 'IMPORT_CART'
};

export const isProduction = process.env.NEXT_PUBLIC_APP_ENV === 'production';
export const isDevelopmentOrRNDEnv = ['development', 'rnd'].includes(
  process.env.NEXT_PUBLIC_APP_ENV
);

export const USER_EVENT_STAGE = {
  CART_COMPLETED: {
    class: 'new',
    key: 'CART_COMPLETED',
    label: 'Cart Completed'
  },
  EPIC_ENHANCED: {
    class: 'in-progress',
    key: 'EPIC_ENHANCED',
    label: 'EPIC Enhanced'
  },
  EPIC_GENERATED: {
    class: 'bg-neutral text-dim-gray',
    key: 'EPIC_GENERATED',
    label: 'EPIC Generated'
  },
  EVENT_ACCEPTED: {
    class: 'in-progress',
    key: 'EVENT_ACCEPTED',
    label: 'Event Accepted'
  },
  EVENT_ASSIGNED: {
    class: 'bg-neutral text-dim-gray',
    key: 'EVENT_ASSIGNED',
    label: 'Event Assigned'
  },
  EVENT_CREATED: {
    class: 'bg-neutral text-dim-gray',
    key: 'EVENT_CREATED',
    label: 'Event Created'
  },
  NOT_CONVERTED: {
    class: 'expired',
    key: 'NOT_CONVERTED',
    label: 'Not Converted'
  },
  ORDER_CONFIRMED: {
    class: 'submitted',
    key: 'ORDER_CONFIRMED',
    label: 'Order Confirmed'
  },
  ORDER_PLACED: {
    class: 'submitted',
    key: 'ORDER_PLACED',
    label: 'Order Placed'
  },
  SOLUTION_DESIGN: {
    class: 'new',
    key: 'SOLUTION_DESIGN',
    label: 'Solution Design'
  }
};

export const ALLOCATION_STATUS = {
  NOT_READY_FOR_ALLOCATION: {
    label: 'Not Ready for Allocation',
    value: 'NOT_READY_FOR_ALLOCATION'
  },
  READY_FOR_ALLOCATION: {
    label: 'Ready for Allocation',
    value: 'READY_FOR_ALLOCATION'
  },
  ACKNOWLEDGEMENT_PENDING: {
    label: 'Acknowledgment Pending',
    value: 'ACKNOWLEDGEMENT_PENDING'
  },
  EVENT_ACCEPTED: {
    label: 'Event Accepted',
    value: 'EVENT_ACCEPTED'
  },
  REASSIGN_REQUESTED: {
    label: 'Reassign Requested',
    value: 'REASSIGN_REQUESTED'
  }
};

export const MANDATORY_FIELDS_FOR_EPIC_ENHANCED = {
  CONTACT_METHOD_ID: 'contactMethodId',
  EVENT_DURATION_IN_DAYS: 'eventDurationInDays',
  EVENT_SITE_TYPE_ID: 'eventSiteTypeId',
  EVENT_START_TIME: 'eventStartTime',
  EVENT_TITLE: 'eventTitle',
  FORMATTED_ADDRESS: 'formattedAddress',
  OPPORTUNITY_VALUE: 'opportunityValue',
  THEME: 'theme',
  ZENDESK_TICKET_ID: 'zendeskTicketId'
};

export const MANDATORY_FIELDS_FOR_EPIC_GENERATED = {
  EVENT_DATE: 'eventDate',
  EVENT_TYPE: 'checkoutEventId',
  EVENT_VERTICALS: 'eventVerticals',
  EXPECTED_GUEST_COUNT: 'expectedGuestCount',
  REQUESTER_NAME: 'eventContactName',
  REQUESTER_TYPE: 'customerName',
  TRIAGE_SUMMARY: 'triageSummary',
  ZENDESK_TICKET_ID: 'zendeskTicketId'
};

export const HOST_NAME_VALIDATION_CONFIG = {
  nameRegex: /^[A-Za-z][A-Za-z0-9_ ,.'-]{2,63}$/,
  nameInputError:
    'Name must be at least 3 characters. Only letters, numbers, spaces, _ and - allowed.'
};

export const NAVIGATION_ENTITY_TYPE = {
  CART: 'CART',
  HOST: 'HOST',
  ORDER: 'ORDER',
  USER_EVENT: 'USER_EVENT'
};

export const EVENT_REQUEST_TYPE = {
  END_TO_END: 'End-to-End',
  PRODUCT_AND_SERVICE_ONLY: 'Product/Service Only'
};

export const NOTIFICATION_TYPE = {
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning'
};

export const DEFAULT_TOAST_CONFIG = {
  message: '',
  show: false,
  status: false
};
