import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useRef } from 'react';

import { Button, Text, TextKeyValuePair } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import { getAvatarText } from '@/lib/utils';
import { removeAuth } from '@/services/identity.service';
import { getMetabaseDashboardHomeURL } from '@/services/opsPortal.service';
import { PAGE_NAME, getPageURL } from '@/services/plannerPortal.service';

const MENU_CONFIG = [
  {
    hoverIcon: 'menu-dashboard-icon.svg',
    icon: 'menu-dashboard-red-icon.svg',
    label: 'Event Pipeline',
    link: getPageURL({ pageName: PAGE_NAME.METABASE_EVENT_PIPELINE.label })
  },
  {
    hoverIcon: 'menu-search-icon.svg',
    icon: 'menu-search-red-icon.svg',
    label: 'Product Search',
    link: getPageURL({ pageName: PAGE_NAME.PRODUCT_SEARCH.label })
  },
  {
    hoverIcon: 'menu-host-icon.svg',
    icon: 'menu-host-red-icon.svg',
    label: 'Hosts',
    link: getPageURL({
      pageName: PAGE_NAME.HOST_LIST.label
    })
  },
  {
    hoverIcon: 'menu-dashboard-icon.svg',
    icon: 'menu-dashboard-red-icon.svg',
    label: 'All Dashboards',
    link: getMetabaseDashboardHomeURL()
  }
];

const MenuHeader = ({ plannerName }) => (
  <div className='bg-sidebar-menu bg-no-repeat bg-cover rounded-2xl h-[8.5rem] p-4'>
    <Text
      {...{
        className: 'text-2xl font-bold text-white',
        content: 'Have an Eventful day 🎉'
      }}
    />
    <div className='flex items-center mt-1 gap-1'>
      <div className='bg-sidebar-avatar flex justify-center items-center bg-no-repeat w-[4.5rem] h-[4.2rem] text-[28px] font-extrabold'>
        <Text
          {...{
            className: 'text-brand-gradient',
            content: getAvatarText({ name: plannerName })
          }}
        />
      </div>
      <TextKeyValuePair
        {...{
          label: plannerName,
          labelClass: 'text-lg font-semibold text-white leading-4',
          value: 'Event Planner',
          valueClassName: 'text-sm font-medium text-white',
          spaceTop: '!gap-1'
        }}
      />
    </div>
  </div>
);

const MenuItem = ({ className, hoverIcon, icon, label, onClick }) => (
  <div
    className={`flex flex-1 py-3 px-4 justify-start items-center gap-4 sidebar-menu rounded-lg ${className || 'hover:bg-brand-gradient'}`}
    onClick={onClick}
  >
    <Image
      alt={label}
      className='menu-white-icon'
      height={0}
      src={`${staticMediaStoreBaseURL}/icons/${icon}`}
      style={{ height: 24, width: 24 }}
      width={0}
    />
    <Image
      alt={label}
      className='hidden menu-red-icon'
      height={0}
      src={`${staticMediaStoreBaseURL}/icons/${hoverIcon}`}
      style={{ height: 24, width: 24 }}
      width={0}
    />
    <Text
      {...{
        className: 'text-nero text-sm md:text-base font-medium',
        content: label
      }}
    />
  </div>
);

const AddHostSection = () => (
  <div className='fixed bottom-14 w-[22rem] bg-add-host-menu-item flex flex-col px-4 py-6 rounded-xl gap-2 text-center'>
    <TextKeyValuePair
      {...{
        className: 'flex-col !gap-1',
        label: 'Let’s Get Started!',
        labelClass: 'text-lg font-semibold text-brand',
        value: 'Create Event Profile In Seconds',
        valueClassName: 'text-nero font-medium'
      }}
    />
    <Button
      {...{
        className: 'bg-brand-gradient text-white py-2 rounded-lg font-medium',
        label: 'Start Now',
        onClick: () =>
          window.open('https://diczfllhwrf.typeform.com/to/Sac8lVxs', '_blank')
      }}
    />
    <Link
      href={getPageURL({
        pageName: PAGE_NAME.HOST_LIST.label,
        queryParams: {
          showHostModal: true
        }
      })}
    >
      <Text
        {...{
          className: 'text-sm font-medium text-brand',
          content: 'Create only host profile'
        }}
      />
    </Link>
  </div>
);

const MenuContainer = ({ plannerName, router, toggleMenu }) => {
  const menuRef = useRef(null);
  useOnClickOutside(menuRef, () => toggleMenu());

  return (
    <div
      className='top-0 absolute -right-10 h-screen bg-white z-10 py-2 px-2'
      ref={menuRef}
    >
      <Image
        alt='menu-red-icon'
        className='ml-auto relative right-8 -top-2'
        height={0}
        onClick={toggleMenu}
        src={`${staticMediaStoreBaseURL}/icons/close-icon-red.svg`}
        style={{ height: 40, width: 40 }}
        width={0}
      />
      <div className='pt-4 relative'>
        <MenuHeader {...{ plannerName }} />
        <div className='flex flex-col text-nero w-[21.8rem] rounded mt-2'>
          {MENU_CONFIG.map(({ hoverIcon, icon, label, link }, index) => (
            <MenuItem
              key={index}
              {...{
                hoverIcon,
                icon,
                label,
                onClick: () => router.push(link)
              }}
            />
          ))}
          <MenuItem
            {...{
              className:
                'fixed bottom-2 flex flex-1 sidebar-logout-menu right-[7rem] !py-2',
              hoverIcon: 'logout-red.svg',
              icon: 'logout.svg',
              label: 'Logout',
              onClick: () => {
                removeAuth();
                router
                  .push(getPageURL({ pageName: PAGE_NAME.WELCOME.label }))
                  .then(() => router.reload());
              }
            }}
          />
        </div>
      </div>
      <AddHostSection />
    </div>
  );
};

const NavigationMenu = ({ isMenuOpen, plannerName, setIsMenuOpen }) => {
  const router = useRouter();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className='flex gap-6 cursor-pointer relative z-20'>
      <div className='w-10 h-10 ai-chat-icon'>
        <div className='inner bg-white w-9 h-9'>
          <Image
            alt='ai-chat-icon'
            className='rise-shake relative left-[5px] top-[6px]'
            height={0}
            onClick={() =>
              router.push(
                getPageURL({
                  pageName: PAGE_NAME.PLANNER_ASSISTANT.label
                })
              )
            }
            src={`${staticMediaStoreBaseURL}/icons/chat-with-ai.svg`}
            style={{ height: 26, width: 26 }}
            width={0}
          />
        </div>
      </div>

      <div
        className='relative welcome-menu shadow rounded-full w-10 h-10'
        onClick={toggleMenu}
      >
        <Image
          alt='menu-white-icon'
          className='menu-white-icon'
          height={0}
          src={`${staticMediaStoreBaseURL}/icons/menu-white.svg`}
          style={{ height: 40, width: 40 }}
          width={0}
        />
        <Image
          alt='menu-red-icon'
          className='hidden menu-red-icon'
          height={0}
          src={`${staticMediaStoreBaseURL}/icons/menu-red.svg`}
          style={{ height: 40, width: 40 }}
          width={0}
        />
      </div>
      {isMenuOpen && <MenuContainer {...{ plannerName, router, toggleMenu }} />}
    </div>
  );
};

export default NavigationMenu;
