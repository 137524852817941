import { PLANNER_API_BASE_URL } from '@/services/connections.service';

export const PLANNER_PRODUCT_INDEX =
  process.env.NEXT_PUBLIC_PLANNER_PRODUCT_INDEX;

export const NAVIGATION_INDEX =
  process.env.NEXT_PUBLIC_PLANNER_NAVIGATION_INDEX;

export const DEFAULT_HITS_PER_PAGE = 24;

export const DEFAULT_FILTER_OPTIONS_LIMIT = 5;

export const MIN_CHAR_FOR_SEARCH_REQUEST = 0;

export const NAVIGATION_TAB_CATEGORY_ATTRIBUTE = 'entityType';

export const NAVIGATION_HITS_PER_PAGE = 6;

export const SEARCH_KIT_API_END_POINT = {
  PRODUCT_SEARCH: `${PLANNER_API_BASE_URL}/product-search`,
  PLANNER_NAVIGATION_SEARCH: `${PLANNER_API_BASE_URL}/planner-navigation/search`
};

export const GENERIC_ALL_TAB_CATEGORY_MAP = {
  activeIcon: 'menu-dashboard-red-icon',
  icon: 'menu-dashboard-icon',
  label: 'All',
  value: 'ALL'
};

export const PRODUCTS_SORT_OPTIONS = [
  {
    value: PLANNER_PRODUCT_INDEX,
    label: 'Relevance'
  },
  {
    value: `${PLANNER_PRODUCT_INDEX}_sortPrice_desc`,
    label: 'Price: High to Low'
  },
  {
    value: `${PLANNER_PRODUCT_INDEX}_sortPrice_asc`,
    label: 'Price: Low to High'
  },
  {
    value: `${PLANNER_PRODUCT_INDEX}_weightage_desc`,
    label: 'Weightage: High to low'
  },
  {
    value: `${PLANNER_PRODUCT_INDEX}_userEventCount_desc`,
    label: 'Top Sellers'
  }
];

export const PRODUCT_SEARCHABLE_ATTRIBUTE_MAP = {
  EVERYWHERE: { key: 'everywhere', value: 'EVERYWHERE', label: 'Everywhere' },
  TITLE: { key: 'name', value: 'TITLE', label: 'Title' },
  SHORT_DESCRIPTION: {
    key: 'shortDescription',
    value: 'SHORT_DESCRIPTION',
    label: 'Short Description'
  },
  DESCRIPTION: {
    key: 'description',
    value: 'DESCRIPTION',
    label: 'Description'
  },
  INCLUSIONS: { key: 'inclusions', value: 'INCLUSIONS', label: 'Inclusions' },
  INTERNAL_ID: {
    key: 'productNumber',
    value: 'INTERNAL_ID',
    label: 'Product Number'
  },
  UUID: { key: 'id', value: 'UUID', label: 'Product ID' }
};

export const NAVIGATION_TAB_CATEGORY = {
  ALL: {
    activeIcon: 'menu-dashboard-red-icon',
    className: 'all-badge',
    icon: 'menu-dashboard-icon',
    label: 'All',
    sortOrder: 0,
    value: 'ALL'
  },
  HOST: {
    activeIcon: 'user-white-icon',
    className: 'text-bluish-cyan',
    icon: 'user-red-with-frame',
    label: 'Host',
    sortOrder: 1,
    value: 'HOST'
  },
  CART: {
    activeIcon: 'order-white-icon',
    className: 'in-progress-badge',
    icon: 'order-red-icon',
    label: 'Cart',
    sortOrder: 3,
    value: 'CART'
  },
  ORDER: {
    activeIcon: 'product-white-icon',
    className: 'submitted-badge',
    icon: 'product-icon-bold',
    label: 'Order',
    sortOrder: 4,
    value: 'ORDER'
  },
  USER_EVENT: {
    activeIcon: 'event-icon-bold',
    className: 'past-badge',
    icon: 'event-red-icon',
    label: 'User Event',
    sortOrder: 2,
    value: 'USER_EVENT'
  }
};

export const NAVIGATION_SEARCHABLE_ATTRIBUTE_COMMON = {
  HOST_MOBILE: {
    key: 'hostMobile',
    label: 'Host Mobile',
    value: 'HOST_MOBILE'
  }
};

const NAVIGATION_SEARCHABLE_ATTRIBUTE_ALL = {
  ...NAVIGATION_SEARCHABLE_ATTRIBUTE_COMMON,
  USER_EVENT_NUMBER: {
    key: 'userEventNumber',
    label: 'User Event Number',
    value: 'USER_EVENT_NUMBER'
  }
};

const NAVIGATION_SEARCHABLE_ATTRIBUTE_HOST = {
  ...NAVIGATION_SEARCHABLE_ATTRIBUTE_COMMON,
  HOST_NAME: { key: 'hostName', label: 'Name', value: 'HOST_NAME' },
  HOST_NUMBER: {
    key: 'hostNumber',
    label: 'Host Number',
    value: 'HOST_NUMBER'
  },
  COMPANY_NAME: {
    key: 'companyName',
    label: 'Company Name',
    value: 'COMPANY_NAME'
  }
};

const NAVIGATION_SEARCHABLE_ATTRIBUTE_ORDER = {
  ...NAVIGATION_SEARCHABLE_ATTRIBUTE_COMMON,
  USER_EVENT_NUMBER: {
    key: 'userEventNumber',
    label: 'User Event Number',
    value: 'USER_EVENT_NUMBER'
  },
  ORDER_NUMBER: {
    key: 'orderNumber',
    label: 'Order Number',
    value: 'ORDER_NUMBER'
  }
};

const NAVIGATION_SEARCHABLE_ATTRIBUTE_CART = {
  ...NAVIGATION_SEARCHABLE_ATTRIBUTE_COMMON,
  USER_EVENT_NUMBER: {
    key: 'userEventNumber',
    label: 'User Event Number',
    value: 'USER_EVENT_NUMBER'
  },
  CART_NUMBER: {
    key: 'cartNumber',
    label: 'Cart Number',
    value: 'CART_NUMBER'
  },
  CART_NAME: {
    key: 'cartName',
    label: 'Cart Name',
    value: 'CART_NAME'
  }
};

const NAVIGATION_SEARCHABLE_ATTRIBUTE_USER_EVENT = {
  ...NAVIGATION_SEARCHABLE_ATTRIBUTE_COMMON,
  USER_EVENT_NUMBER: {
    key: 'userEventNumber',
    label: 'User Event Number',
    value: 'USER_EVENT_NUMBER'
  },
  ZENDESK_TICKET_NUMBER: {
    key: 'zendeskTicketNumber',
    label: 'Zendesk Ticket Number',
    value: 'ZENDESK_TICKET_NUMBER'
  }
};

export const NAVIGATION_SEARCHABLE_ATTRIBUTE_MAP = {
  [NAVIGATION_TAB_CATEGORY.ALL.value]: NAVIGATION_SEARCHABLE_ATTRIBUTE_ALL,
  [NAVIGATION_TAB_CATEGORY.HOST.value]: NAVIGATION_SEARCHABLE_ATTRIBUTE_HOST,
  [NAVIGATION_TAB_CATEGORY.CART.value]: NAVIGATION_SEARCHABLE_ATTRIBUTE_CART,
  [NAVIGATION_TAB_CATEGORY.ORDER.value]: NAVIGATION_SEARCHABLE_ATTRIBUTE_ORDER,
  [NAVIGATION_TAB_CATEGORY.USER_EVENT.value]:
    NAVIGATION_SEARCHABLE_ATTRIBUTE_USER_EVENT
};
