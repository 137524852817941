import {
  DatePickerWithLabel,
  InputField,
  RichTextEditor,
  SelectFormInput,
  SelectTags,
  Text
} from '@/components/atomic/nuclei';
import { formatDateToHaflaStandardFormat } from '@/lib/time';
import { checkIsEmptyOrDummyEventDetail } from '@/services/eventCartDetail.service';
import { EVENT_PROFILE_FORM_STEPS } from '@/services/hostEvent.service';

const EventProfileStep = ({
  formActiveStepId,
  clearErrors,
  control,
  errors,
  eventFormDetails,
  eventVerticals,
  getValues,
  isUserEventDetailInEditMode,
  register,
  selectedEventRequestVerticals,
  setEventFormDetails,
  setSelectedEventRequestVerticals,
  setValue
}) => {
  const { checkoutEvents, selectedEventDate } = eventFormDetails || {};

  const handleKeyDown = (event) => {
    const invalidKeys = ['-', '+', 'e', 'E', '.'];
    if (invalidKeys.includes(event.key)) {
      event.preventDefault();
    }
  };

  const handleZendeskTicketInput = (e) =>
    setEventFormDetails((prevState) => ({
      ...prevState,
      zendeskTicketId: e.target.value
    }));

  const dummyValueErrorStyleForEventDate = checkIsEmptyOrDummyEventDetail({
    key: 'eventDate',
    value: formatDateToHaflaStandardFormat(selectedEventDate)
  })
    ? 'text-error-base'
    : '';

  const formStepVisibilityClass =
    formActiveStepId === EVENT_PROFILE_FORM_STEPS.BASIC_EVENT_PROFILE.id
      ? 'flex'
      : 'hidden';

  return (
    <div
      className={`flex-col gap-4 px-8 ${formStepVisibilityClass}`}
      id={EVENT_PROFILE_FORM_STEPS.BASIC_EVENT_PROFILE.id}
    >
      <InputField
        {...{
          className: 'rounded-lg pl-10',
          dbName: 'zendeskTicketId',
          disabled: !isUserEventDetailInEditMode,
          iconURL: 'zendesk-icon-black.svg',
          inputGroup: 'flex flex-col gap-2',
          label: 'Zendesk Ticket',
          labelClass: 'text-nero text-base font-medium',
          maxLength: 6,
          onChange: handleZendeskTicketInput,
          onKeyDown: handleKeyDown,
          onWheel: (e) => e.target.blur(),
          placeholder: 'Enter zendesk ticket',
          type: 'number',
          register: register('zendeskTicketId')
        }}
      />
      <SelectFormInput
        {...{
          clearErrors,
          containerClassName:
            'relative multi-select-epm epm-dropdown border border-platinum rounded',
          control,
          dbName: 'checkoutEventId',
          disabled: !isUserEventDetailInEditMode,
          errors,
          getValues,
          icon: {
            url: isUserEventDetailInEditMode
              ? 'event-red-icon.svg'
              : 'event-icon-black.svg'
          },
          isClearable: false,
          label: 'Event Type *',
          name: 'Checkout Event Id',
          placeholderLabel: 'Event Type',
          register,
          setValue,
          showIcon: true,
          showLabel: true,
          values: checkoutEvents
        }}
      />
      <div className='flex gap-4'>
        <div className='flex w-1/2'>
          <DatePickerWithLabel
            {...{
              datePickerClassName: `text-sm font-medium leading-5 placeholder-silver cursor-pointer focus:outline-none w-full h-8 p-2 border-0 ${dummyValueErrorStyleForEventDate}`,
              dbName: 'eventDate',
              disabled: !isUserEventDetailInEditMode,
              errors,
              label: 'Event Date *',
              placeholder: 'Choose Event Date',
              selectedEventDate,
              setSelectedEventDate: (selectedDate) =>
                setEventFormDetails(() => ({
                  ...eventFormDetails,
                  selectedEventDate: selectedDate
                })),
              setValue
            }}
          />
        </div>
        <InputField
          {...{
            className: 'rounded-lg pl-10',
            dbName: 'expectedGuestCount',
            disabled: !isUserEventDetailInEditMode,
            errors,
            iconURL: isUserEventDetailInEditMode
              ? 'guest-icon-red.svg'
              : 'guest-icon-black.svg',
            inputGroup: 'flex flex-col gap-2 flex-1',
            label: 'Expected # of guests',
            labelClass: 'text-nero text-base font-medium',
            placeholder: 'Enter Expected # of guests',
            register: { ...register('expectedGuestCount') },
            type: 'number'
          }}
        />
      </div>
      <div className='flex flex-col gap-2 mt-1'>
        <Text
          {...{
            className: 'text-nero text-base font-medium',
            content: 'Hafla Request Vertical(s)'
          }}
        />
        <SelectTags
          {...{
            isEditable: isUserEventDetailInEditMode,
            selectedTagOptions: selectedEventRequestVerticals,
            setSelectedTagOptions: setSelectedEventRequestVerticals,
            tagOptions: eventVerticals
          }}
        />
      </div>
      <div className='flex flex-col gap-2'>
        <Text
          {...{
            className: 'text-nero text-base font-medium',
            content: 'Summary'
          }}
        />
        <div
          className={`border border-platinum rounded-lg overflow-hidden epm-summary-rich-editor ${isUserEventDetailInEditMode ? 'hover:border-brand' : ''}`}
        >
          <RichTextEditor
            {...{
              dbKey: 'triageSummary',
              editorStyle: 'h-20 p-2 overflow-y-auto',
              readOnly: !isUserEventDetailInEditMode,
              setValue,
              values: getValues('triageSummary')
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default EventProfileStep;
