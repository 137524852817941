import { Modal } from 'reactstrap';

import { CTA, Text } from '@/components/atomic/nuclei';

const AlertModalWithCTA = ({ actionButtonList, message }) => (
  <Modal
    centered={true}
    isOpen={true}
  >
    <div className='flex justify-center h-screen'>
      <div className='flex flex-col self-center bg-white items-center gap-6 justify-center text-center w-108 rounded-lg mx-auto p-6'>
        <Text
          {...{
            className: 'font-medium text-base text-nero',
            content: message
          }}
        />
        <div className='flex gap-4'>
          {actionButtonList.map(
            ({ buttonColor, className, label, onClick }, index) => (
              <CTA
                key={`${label} - ${index}`}
                {...{
                  buttonColor,
                  className,
                  label,
                  onClick
                }}
              />
            )
          )}
        </div>
      </div>
    </div>
  </Modal>
);

export default AlertModalWithCTA;
