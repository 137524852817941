import Image from 'next/image';

import { Text, TextKeyValuePair } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import { getHostEventStatisticsParamaters } from '@/services/hostEvent.service';

const HostEventStatistics = ({
  createdAt: createdAtRaw,
  hostEventStatistics
}) => {
  const {
    createdAt,
    userEventCount,
    userEventsAEV,
    userEventsConverted,
    userEventsGEV
  } = getHostEventStatisticsParamaters({
    createdAt: createdAtRaw,
    hostEventStatistics
  });

  return (
    <div className='bg-statistic bg-no-repeat flex flex-1 flex-col bg-cover'>
      <div className='flex flex-col text-brand justify-center items-center py-4'>
        <TextKeyValuePair
          {...{
            className: 'flex text-sm !gap-1 mb-1.5',
            label: 'Host Since',
            labelClass: 'font-medium',
            value: createdAt,
            valueClassName: 'font-semibold'
          }}
        />

        <Text
          {...{
            className: 'font-semibold text-xl',
            content: 'Here’s The Hafla Summary'
          }}
        />
      </div>
      <div className='flex gap-8 px-6 pb-6 pt-4'>
        <TextKeyValuePair
          {...{
            className:
              'bg-total-event w-[140px] h-[140px] flex flex-col px-4 text-center justify-center items-center gap-0',
            label: userEventCount.value,
            labelClass: 'text-brand font-semibold text-[28px] leading-6',
            value: userEventCount.label,
            valueClassName: 'text-sm text-nero font-medium leading-4'
          }}
        />

        <div className='flex gap-4 flex-col'>
          <div className='flex items-center gap-2'>
            <Image
              alt={userEventsConverted.alt}
              height={0}
              src={`${staticMediaStoreBaseURL}/icons/${userEventsConverted.icon}`}
              style={{ height: 28, width: 28 }}
              width={0}
            />
            <TextKeyValuePair
              {...{
                className: 'flex flex-col !gap-0',
                label: userEventsConverted.value,
                labelClass: 'text-xl text-brand font-semibold',
                value: userEventsConverted.label,
                valueClassName: 'text-xs font-medium text-nero'
              }}
            />
          </div>
          <div className='flex items-center gap-2'>
            <Image
              alt={userEventsGEV.alt}
              height={0}
              src={`${staticMediaStoreBaseURL}/icons/${userEventsGEV.icon}`}
              style={{ height: 28, width: 28 }}
              width={0}
            />
            <TextKeyValuePair
              {...{
                className: 'flex flex-col !gap-0',
                label: userEventsGEV.value,
                labelClass: 'text-xl text-brand font-semibold',
                value: userEventsGEV.label,
                valueClassName: 'text-xs font-medium text-nero'
              }}
            />
          </div>
          <div className='flex items-center gap-2'>
            <Image
              alt={userEventsAEV.alt}
              height={0}
              src={`${staticMediaStoreBaseURL}/icons/${userEventsAEV.icon}`}
              style={{ height: 28, width: 28 }}
              width={0}
            />
            <TextKeyValuePair
              {...{
                className: 'flex flex-col !gap-0',
                label: userEventsAEV.value,
                labelClass: 'text-xl text-brand font-semibold',
                value: userEventsAEV.label,
                valueClassName: 'text-xs font-medium text-nero'
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HostEventStatistics;
