import Uppy from '@uppy/core';
import { DashboardModal } from '@uppy/react';
import ImageKitUppyPlugin from 'imagekit-uppy-plugin';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { PropTypes } from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';

import { LINE_ITEM_SOURCE, staticMediaStoreBaseURL } from '@/config/common';
import {
  getImageKitAuth,
  reconcileCartItemMediaWithImageKit
} from '@/services/cart.plan.service';
import { reconcileOrderItemMediaWithImageKit } from '@/services/order.service';

const environmentName = process.env.NEXT_PUBLIC_APP_ENV;
const imageKitPublicKey = process.env.NEXT_PUBLIC_IMAGEKIT_PUBLIC_KEY;

// TODO: Hardik, pl. add toast message in case API response status is not true, or if code lands in catch block.
const imageKitAuthenticator = async () => {
  try {
    const { entity } = await getImageKitAuth();
    return entity;
  } catch (error) {
    throw new Error(`Authentication request failed: ${error.message}`);
  }
};

const metaFields = [
  {
    id: 'customCoordinates',
    name: 'Custom coordinates',
    placeholder: 'Comma seperated values in format x,y,width,height'
  },
  {
    id: 'isPrivateFile',
    name: 'Private File',
    render({ value, onChange }, h) {
      return h('input', {
        type: 'checkbox',
        onChange: (ev) => onChange(ev.target.checked ? 'true' : 'false'),
        checked: value === 'true' || value === true,
        style: {
          verticalAlign: 'middle'
        }
      });
    }
  },
  {
    id: 'name',
    name: 'File name',
    placeholder: 'Enter the file name'
  },
  {
    id: 'tags',
    name: 'Tags',
    placeholder: 'Comma seperated tags e.g. t-shirt, summer'
  },
  {
    id: 'useUniqueFileName',
    name: 'Use unique file name',
    render({ value, onChange }, h) {
      return h('input', {
        type: 'checkbox',
        onChange: (ev) => onChange(ev.target.checked ? 'true' : 'false'),
        checked: value === '' || value === 'true' || value === true,
        style: {
          verticalAlign: 'middle'
        }
      });
    }
  }
];

const getCartItemMediaDirectoryPath = ({ cartId, cartItemId }) =>
  `API/${environmentName}/db/carts/${cartId}/${cartItemId}/`;

const getOrderItemMediaDirectoryPath = ({ orderId, orderItemId }) =>
  `API/${environmentName}/db/orders/${orderId}/${orderItemId}/`;

const contentSink = `/API/${environmentName}/dump`;

const getUppyInstance = ({ directoryPath }) => {
  const uppy = new Uppy({
    id: 'Uppy',
    allowMultipleUploads: true,
    autoProceed: false,
    debug: false,
    restrictions: {
      allowedFileTypes: ['image/*'],
      maxFileSize: 15 * 1048576,
      maxNumberOfFiles: 10
    },
    onBeforeUpload: (files) => {
      const updatedFiles = {};
      Object.keys(files).forEach((fileKey) => {
        updatedFiles[fileKey] = {
          ...files[fileKey],
          meta: {
            ...files[fileKey].meta,
            folder: directoryPath || contentSink,
            useUniqueFileName: true
          }
        };
      });
      return updatedFiles;
    }
  }).use(ImageKitUppyPlugin, {
    id: 'ImageKitUppyPlugin',
    authenticator: imageKitAuthenticator,
    publicKey: imageKitPublicKey,
    metaFields: [
      'folder',
      'customCoordinates',
      'isPrivateFile',
      'name',
      'responseFields',
      'tags',
      'useUniqueFileName'
    ]
  });
  return uppy;
};

const UploadCartItemMediaModal = ({
  cartId,
  cartItemId,
  orderId,
  orderItemId,
  productSource,
  setCartItemIdForUploadMediaModal,
  setOrderItemIdForUploadMediaModal,
  setShowToast
}) => {
  const router = useRouter();
  const directoryPath =
    productSource === LINE_ITEM_SOURCE.ORDER.value
      ? getOrderItemMediaDirectoryPath({ orderId, orderItemId })
      : getCartItemMediaDirectoryPath({ cartId, cartItemId });
  const uppy = getUppyInstance({ directoryPath });
  uppy.on('upload-success', (success) => success);
  uppy.on('complete', (result) => result);
  uppy.on('error show toast message', (error) => error);
  uppy.on('upload-error show toast message', (error) => error);

  const uninstallPluginCloseModal = () => {
    uppy.close();
    if (productSource === LINE_ITEM_SOURCE.ORDER.value) {
      setOrderItemIdForUploadMediaModal(null);
    } else {
      setCartItemIdForUploadMediaModal(null);
    }
  };

  const doneButtonHandler = async () => {
    // TODO:  Rajesh/Hardik: ”media is uploaded, wait a few minutes to see in image carousal” block screen/loader
    let apiStatus;
    let apiMessage;
    if (productSource === LINE_ITEM_SOURCE.ORDER.value) {
      const { status, message } = await reconcileOrderItemMediaWithImageKit({
        orderId,
        orderItemId
      });
      apiStatus = status;
      apiMessage = message;
    } else {
      const { status, message } = await reconcileCartItemMediaWithImageKit({
        cartId,
        cartItemId
      });
      apiStatus = status;
      apiMessage = message;
    }
    if (apiStatus) {
      router.reload();
    } else {
      setShowToast({ show: true, status: apiStatus, message: apiMessage });
    }
  };

  return (
    <Modal
      isOpen={true}
      toggle={uninstallPluginCloseModal}
      fade={true}
    >
      <ModalBody>
        <div className='flex h-screen justify-center'>
          <div className='self-center relative'>
            <span
              className='flex justify-end cursor-pointer relative top-7 -left-2 z-50'
              onClick={uninstallPluginCloseModal}
            >
              <Image
                alt='cross'
                height={0}
                layout='fixed'
                src={`${staticMediaStoreBaseURL}/icons/close-with-border-gray.svg`}
                style={{ height: 20, width: 20 }}
                width={0}
              />
            </span>
            <DashboardModal
              animateOpenClose={true}
              browserBackButtonClose={true}
              centerSingleFile={true}
              closeAfterFinish={false}
              closeModalOnClickOutside={true}
              disablePageScrollWhenModalOpen={true}
              disableStatusBar={false}
              doneButtonHandler={doneButtonHandler}
              fileManagerSelectionType='files'
              hideAfterFinish={false}
              hideProgressAfterFinish={false}
              id='DashboardModal'
              inline={true}
              metaFields={metaFields}
              name='DashboardModalName'
              onRequestCloseModal={uninstallPluginCloseModal}
              open={true}
              plugins={['dashboard']}
              proudlyDisplayPoweredByUppy={false}
              showLinkToFileUploadResult={true}
              showProgressDetails={true}
              showRemoveButtonAfterComplete={true}
              theme='auto'
              uppy={uppy}
              // trigger='#uppyDashboard'
              // target='.uppy-dashboard-container'
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default UploadCartItemMediaModal;

UploadCartItemMediaModal.propTypes = {
  cartId: PropTypes.string,
  cartItemId: PropTypes.string,
  setCartItemIdForUploadMediaModal: PropTypes.func
};
