import Image from 'next/image';

import { Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import { getUserEventCartDetails } from '@/services/cart.plan.service';

const CartEventInfo = ({ cartDetails, userEventSummary }) => {
  if (!userEventSummary.id) {
    return '';
  }
  const { userEventDetails: [userEventDetails] = [] } = userEventSummary || {};
  const { eventTitle } = userEventDetails;
  const { userEventDetailsBody } = getUserEventCartDetails({
    userCartDetails: cartDetails
  });
  return (
    <div className='p-5 bg-lightgray rounded-b-md'>
      <div className='flex flex-col md:flex-row justify-start gap-4'>
        <Text
          className='font-semibold text-nero'
          content={eventTitle || 'NA'}
        />
      </div>
      <div className='flex flex-col md:flex-row justify-between bg-lightgray mt-4'>
        <div className='grid grid-cols-1 md:grid-cols-4 md:grid-rows-2 gap-2 mt-2 md:mt-0'>
          {userEventDetailsBody.map(({ alt, className, icon, value }) => (
            <div
              key={value}
              className={`flex gap-3 md:gap-2 md:w-96 ${className}`}
            >
              <div className='flex'>
                <Image
                  alt={alt}
                  height={0}
                  src={`${staticMediaStoreBaseURL}/icons/${icon}`}
                  style={{ height: 20, width: 20 }}
                  width={0}
                />
              </div>
              <Text
                {...{
                  className:
                    'flex self-center text-sm md:text-base font-light text-nero max-w-52 truncate',
                  content: value
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CartEventInfo;
