const tailwindcssRtl = require('tailwindcss-rtl');

const STATIC_MEDIA_STORE_PATH_STUB = '/static_images/planner';
const mediaStoreBaseURL =
  process.env.STORYBOOK_PUBLIC_MEDIA_STORE_URL ||
  process.env.NEXT_PUBLIC_MEDIA_STORE_URL ||
  '';
const staticMediaStoreBaseURL = `${mediaStoreBaseURL}${STATIC_MEDIA_STORE_PATH_STUB}`;

const colors = {
  brand: '#EA4842',
  nero: '#222222',
  platinum: '#E4E4E4',
  orange: '#FF794D',
  silver: '#BABABA',
  gray: '#EDEDED',
  'cinnamon-satin': '#D25D7F',
  'dim-gray': '#717171',
  'error-red': '#FF3200',
  cinnabar: '#EE3D24',
  'base-white': '#FCFCFC',
  green: '#009518',
  'chinese-black': '#0C0C1E',
  greenlight: '#EAF3EB',
  'green-base': '#29823B',
  'error-base': '#DC2020',
  'error-base-bg': '#FCEAEA',
  'info-base': '#017AAD',
  'info-light': '#E5F5FC',
  'warning-light': '#FDF4E5',
  'light-red': '#FFF1EC',
  'warning-dark': '#E99400',
  neutral: '#F3F3F6',
  'planner-cart-head': '#F9F9FB',
  'basic-black': '#0C0C1E',
  'ghost-white': '#F6F6F7',
  highlight: '#FFD',
  lightgray: '#FCFCFC',
  blue: '#0000BB',
  'error-tool-tip': '#FBEFF2',
  'rich-black': '#020202'
};

module.exports = {
  content: [
    './atoms/*.js',
    './atoms/**/*.js',
    './components/*.js',
    './components/**/*.js',
    './components/**/**/*.js',
    './components/**/**/**/*.js',
    './molecules/*.js',
    './molecules/**/*.js',
    './molecules/**/**/*.js',
    './molecules/**/**/**/*.js',
    './organisms/*.js',
    './organisms/**/*.js',
    './organisms/**/**/*.js',
    './organisms/**/**/**/*.js',
    './pages/*.js',
    './pages/**/*.js',
    './pages/**/**/*.js',
    './pages/**/**/**/*.js',
    './templates/*.js',
    './templates/**/*.js',
    './templates/**/**/*.js',
    './templates/**/**/**/*.js'
  ],
  safelist: [
    'bg-all-event-tile',
    'bg-book-event-tile',
    'bg-event-gev-tile',
    'bg-event-aev-tile',
    'curve-border',
    'sidebar-menu',
    'sidebar-avatar'
  ],
  theme: {
    screens: {
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      '2xl': '1440px'
    },
    extend: {
      borderColor: () => ({
        ...colors
      }),

      backgroundColor: () => ({
        ...colors
      }),

      textColor: () => ({
        ...colors
      }),
      content: () => ({
        'searchkit-checkbox': `url('${staticMediaStoreBaseURL}/icons/square.svg')`,
        'searchkit-checkbox-checked': `url('${staticMediaStoreBaseURL}/icons/checked.svg')`,
        'searchkit-filter-menu-item-checked': `url('${staticMediaStoreBaseURL}/icons/radio-selected.svg')`
      }),
      backgroundImage: () => ({
        'add-host-menu-item':
          'background: linear-gradient(66.43deg, #E58023 -33.51%, #CF578F 94.65%, #E03D24 207.46%);',
        'all-event-tile': `url('${staticMediaStoreBaseURL}/icons/total-event-bg.svg')`,
        'book-event-tile': `url('${staticMediaStoreBaseURL}/icons/book-event-bg.svg')`,
        'brand-gradient':
          'linear-gradient(66.43deg, #E58023 -33.51%, #CF578F 94.65%, #E03D24 207.46%);',
        'curve-border': `url('${staticMediaStoreBaseURL}/icons/curve-border.svg')`,
        'event-aev-tile': `url('${staticMediaStoreBaseURL}/icons/event-aev-bg.svg')`,
        'event-gev-tile': `url('${staticMediaStoreBaseURL}/icons/event-gev-bg.svg')`,
        'host-event-sidebar': `url('${staticMediaStoreBaseURL}/icons/host-event-profile.svg')`,
        'light-red':
          'linear-gradient(66.43deg, rgba(229, 128, 35, 0.15) -33.51%, rgba(207, 87, 143, 0.15) 94.65%, rgba(224, 61, 36, 0.15) 207.46%);',
        'product-button': `url('${staticMediaStoreBaseURL}/icons/product-button-bg.svg')`,
        'product-price-bg-bottom': `url('${staticMediaStoreBaseURL}/icons/product-price-bg-bottom.svg')`,
        'product-price-bg-left': `url('${staticMediaStoreBaseURL}/icons/product-price-bg-left.svg')`,
        'searchkit-filter-menu-item-checked': `url('${staticMediaStoreBaseURL}/icons/radio-selected.svg')`,
        'searchkit-filter-menu-item': `url('${staticMediaStoreBaseURL}/icons/radio.svg')`,
        'searchkit-search-box-form': `url('${staticMediaStoreBaseURL}/icons/search_new.svg')`,
        'sidebar-avatar': `url('${staticMediaStoreBaseURL}/icons/menu-header-avtar.svg')`,
        'sidebar-menu': `url('${staticMediaStoreBaseURL}/icons/sidebar-menu-bg-icon.svg')`,
        'total-event': `url('${staticMediaStoreBaseURL}/icons/total-event-bg-icon.svg')`,
        search: `url('${staticMediaStoreBaseURL}/icons/search-bg.svg')`,
        since: `url('${staticMediaStoreBaseURL}/icons/since-bg.png')`,
        statistic: `url('${staticMediaStoreBaseURL}/icons/statistic-box.svg')`
      }),
      gradientColorStops: () => ({
        red: '#EB3349',
        orange: '#F45C43',
        indigo: '#4B0082',
        pumpkins: '#F38023',
        hopbush: '#DD579D',
        cinnabar: '#EE3D24',
        'light-orange': '#F38023 -90.01%',
        'dark-orange': '#DD579D 205.57%',
        rose: '#EE3D24 467.37%'
      }),
      divideColor: () => ({
        gray: '#EDEDED'
      }),
      boxShadow: () => ({
        'product-card': '0px 6px 12px 0px #0000001C',
        'event-card-tile': '0px 4px 10px 0px #F2499426',
        card: '0px 2px 8px 0px rgba(0, 0, 0, 0.08)',
        'navigate-cart': '0px 2px 8px 0px #00000014',
        profile: '0px 2px 6px #E9E9E9',
        category: '0px 2px 8px rgba(0, 0, 0, 0.12)'
      }),
      minWidth: () => ({}),
      height: () => ({
        4.5: '4.5rem',
        126: '31.25rem',
        50: '12.5rem',
        528: '33.625rem'
      }),
      minHeight: () => ({}),
      maxHeight: () => ({
        152: '38rem'
      }),
      width: () => ({
        150: '37.5rem',
        108: '31rem',
        112: '28rem',
        126: '31.25rem',
        128: '32rem',
        15: '3.75rem',
        20.5: '20.5rem',
        21: '21rem',
        30: '7.5rem',
        35: '35rem',
        45.6: '45.625rem',
        50: '50rem',
        56.8: '56.875rem',
        68: '68rem',
        22: '5.5rem',
        41: '41.875rem',
        56.25: '56.25rem',
        100: '25rem',
        42.5: '10.625rem',
        23.75: '23.75rem'
      }),
      maxWidth: () => ({
        50: '12.5rem',
        116.5: '29.125rem'
      }),
      borderRadius: () => ({
        2.5: '0.625rem'
      }),
      fontSize: () => ({
        '10px': '0.625rem',
        '3xl': '32px',
        '4xl': '40px'
      }),
      zIndex: () => ({
        99: 99,
        101: 101,
        120: 120,
        150: 150,
        151: 151,
        1200: 1200
      }),
      inset: () => ({
        18: '4.5rem'
      }),
      margin: {}
    }
  },
  plugins: [tailwindcssRtl]
};
