import _ from 'lodash';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useMenu } from 'react-instantsearch';
import { createInstantSearchRouterNext } from 'react-instantsearch-router-nextjs';

import { Text, TextWithIcon } from '@/components/atomic/nuclei';
import { GENERIC_ALL_TAB_CATEGORY_MAP } from '@/config/searchKit';
import { getMenuFromCurrentURL } from '@/helpers/searchkit';

const tabStyle = `navigate-cart border-t border-r border-l self-center items-center border-neutral w-36 bg-white text-sm px-4 py-[10px] cursor-pointer font-medium flex rounded-t-lg justify-center`;
const tabLabelStyle = 'searchkit-list-label text-nowrap';
const tabCountStyle = `ais-Menu-count searchkit-menu-list-count text-xs font-semibold rounded-full flex justify-center items-center align-middle bg-light-red text-dim-gray border-none min-w-6`;

const handleTabMenuClick = ({
  additionalTabMenuClickOperation,
  isRefined,
  refine,
  setSelectedTabMenu,
  value
}) => {
  if (!isRefined) {
    setSelectedTabMenu((prevSelectedValue) =>
      prevSelectedValue === value ? GENERIC_ALL_TAB_CATEGORY_MAP.value : value
    );
    refine(value);
    additionalTabMenuClickOperation();
  }
};

const getTransformItems = ({ items, selectedTabMenu, tabCategoryKeysSorted }) =>
  tabCategoryKeysSorted
    .map(({ activeIcon, icon, label, value: expectedBucketValue }) => {
      const bucket = items.find(
        ({ value: bucketValue }) => expectedBucketValue === bucketValue
      );
      const defaultBucket = {
        activeIcon,
        count: 0,
        data: null,
        exhaustive: true,
        icon,
        isRefined: selectedTabMenu === expectedBucketValue,
        label,
        value: expectedBucketValue
      };

      return bucket ? { ...bucket, activeIcon, icon, label } : defaultBucket;
    })
    .filter(Boolean);

// TODO: Viresh to update Image style in JSX
const TabMenuOptions = ({
  additionalTabMenuClickOperation,
  refine,
  setSelectedTabMenu,
  showTabMenuCount,
  transformItems
}) =>
  transformItems.map(
    ({ activeIcon, count, icon, isRefined, label, value }, key) => (
      <li
        className={`${tabStyle} ${isRefined ? 'selected-menu-tab bg-brand-gradient text-white' : 'text-dim-gray'} `}
        key={`${value}_${key}`}
        onClick={() =>
          handleTabMenuClick({
            additionalTabMenuClickOperation,
            isRefined,
            refine,
            setSelectedTabMenu,
            value
          })
        }
      >
        <div className='flex gap-2 justify-center items-center'>
          <TextWithIcon
            {...{
              className: 'shadow-none',
              icon: `${isRefined ? activeIcon : icon}.svg`,
              iconHeight: 20,
              iconWidth: 20,
              label,
              labelStyle: tabLabelStyle
            }}
          />

          {showTabMenuCount && (
            <Text
              {...{
                className: tabCountStyle,
                content: count,
                HtmlTag: 'span'
              }}
            />
          )}
        </div>
      </li>
    )
  );

const AllTabMenu = ({
  allMenuCount,
  handleAllMenuClick,
  isOtherMenuSelected,
  selectedTabMenu,
  showTabMenuCount
}) => {
  const { activeIcon, icon, label, value } = GENERIC_ALL_TAB_CATEGORY_MAP;
  const isAllMenuSelected =
    _.isNull(selectedTabMenu) || selectedTabMenu === value;
  return (
    <li
      className={`${tabStyle} ${
        isOtherMenuSelected
          ? 'text-dim-gray'
          : 'selected-menu-tab bg-brand-gradient text-white'
      } `}
      key={value}
      onClick={handleAllMenuClick}
    >
      <div
        {...{
          className: 'flex gap-2 justify-center items-center'
        }}
      >
        <TextWithIcon
          {...{
            className: 'shadow-none',
            icon: `${isAllMenuSelected ? icon : activeIcon}.svg`,
            iconHeight: 20,
            iconWidth: 20,
            label,
            labelStyle: tabLabelStyle
          }}
        />

        {showTabMenuCount && (
          <Text
            {...{
              className: tabCountStyle,
              content: allMenuCount,
              HtmlTag: 'span'
            }}
          />
        )}
      </div>
    </li>
  );
};

const TabMenu = ({
  additionalTabMenuClickOperation = () => {},
  attribute,
  index,
  selectedTabMenu,
  setSelectedTabMenu,
  showTabMenuCount = true,
  tabCategoryKeysSorted
}) => {
  const { items, refine } = useMenu({ attribute });
  const router = useRouter();
  const instantSearchRouter = createInstantSearchRouterNext({
    routerOptions: {
      cleanUrlOnDispose: false
    }
  });

  const handlePopState = () => {
    getMenuFromCurrentURL({
      attribute,
      index,
      instantSearchRouter,
      setSelectedTab: setSelectedTabMenu
    });
  };

  useEffect(() => {
    handlePopState();
    router.beforePopState(() => {
      handlePopState();
      return true;
    });

    return () => {
      router.beforePopState(() => true);
    };
  }, [router]);

  const transformItems = getTransformItems({
    items,
    selectedTabMenu,
    tabCategoryKeysSorted
  });
  const isOtherMenuSelected = transformItems.some(({ isRefined }) => isRefined);

  const allMenuCount = items.reduce(
    (accumulator, { count }) => accumulator + count,
    0
  );

  const handleAllMenuClick = () => {
    if (isOtherMenuSelected) {
      refine('');
      setSelectedTabMenu(GENERIC_ALL_TAB_CATEGORY_MAP.value);
      additionalTabMenuClickOperation();
    }
  };

  return (
    <div className='-mt-8 mx-6 md:mt-0 md:mx-0 searchkit-list-menu'>
      <ul className='flex gap-3 items-center overflow-auto'>
        <AllTabMenu
          {...{
            allMenuCount,
            handleAllMenuClick,
            isOtherMenuSelected,
            selectedTabMenu,
            showTabMenuCount
          }}
        />
        <TabMenuOptions
          {...{
            additionalTabMenuClickOperation,
            refine,
            setSelectedTabMenu,
            showTabMenuCount,
            transformItems
          }}
        />
      </ul>
    </div>
  );
};

export default TabMenu;
