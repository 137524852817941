import { InputField, SelectTags } from '@/components/atomic/nuclei';
import { TAG_OPTIONS_SELECTION_MODE } from '@/config/common';
import { hostProfileTabs } from '@/services/hostProfile.service';

const HostProfileStepOne = ({
  errors,
  formActiveStepId,
  hostProfileMetadata,
  isEditable,
  register,
  setValue,
  watch
}) => {
  const { hostTypeList } = hostProfileMetadata;
  const formStepVisibilityClass =
    formActiveStepId === hostProfileTabs.HOST_PROFILE.id ? 'block' : 'hidden';

  return (
    <div className={formStepVisibilityClass}>
      <div className='bg-white rounded-lg px-6 pb-4 flex flex-col gap-4 block'>
        <InputField
          {...{
            className: 'rounded-lg pl-10 !py-4',
            dbName: 'name',
            disabled: !isEditable,
            errors,
            iconURL: isEditable
              ? 'user-red-with-frame.svg'
              : 'user-icon-black.svg',
            inputGroup: 'flex flex-col gap-2',
            label: 'Host Name *',
            labelClass: 'text-nero text-base font-medium',
            maxLength: 64,
            placeholder: 'Enter Contact Name',
            register: {
              ...register('name')
            }
          }}
        />
        <div className='flex gap-4'>
          <InputField
            {...{
              className: 'rounded-lg pl-10 !py-4',
              dbName: 'mobile',
              disabled: true,
              iconURL: isEditable
                ? 'call-with-frame.svg'
                : 'phone-icon-black.svg',
              inputGroup: 'flex flex-col gap-2 flex-1',
              label: 'Mobile Number',
              labelClass: 'text-nero text-base font-medium',
              maxLength: 15,
              register: { ...register('mobile') }
            }}
          />
          <InputField
            {...{
              className: 'rounded-lg pl-10 !py-4',
              dbName: 'email',
              disabled: !isEditable,
              iconURL: isEditable ? 'email-icon.svg' : 'email-icon-black.svg',
              inputGroup: 'flex flex-col gap-2 flex-1',
              label: 'Contact Email',
              labelClass: 'text-nero text-base font-medium',
              maxLength: 50,
              onChange: (e) => setValue('email', e.target.value),
              placeholder: 'Enter Contact Email',
              register: { ...register('email') }
            }}
          />
        </div>
        <SelectTags
          {...{
            isEditable,
            selectedTagOptions: watch('customerSegmentId'),
            setSelectedTagOptions: (value) =>
              setValue('customerSegmentId', value),
            tagOptions: hostTypeList,
            tagOptionsSelectionMode: TAG_OPTIONS_SELECTION_MODE.SINGLE,
            tagsFieldLabel: { label: 'Host Type' }
          }}
        />
        <InputField
          {...{
            className: 'rounded-lg pl-10 !py-4',
            dbName: 'companyName',
            disabled: !isEditable,
            iconURL: isEditable ? 'company-icon.svg' : 'company-icon-black.svg',
            inputGroup: 'flex flex-col gap-2 flex-1',
            label: 'Company Name',
            labelClass: 'text-nero text-base font-medium',
            maxLength: 50,
            onChange: (e) => setValue('companyName', e.target.value),
            placeholder: 'Enter Company Name',
            register: { ...register('companyName') }
          }}
        />
      </div>
    </div>
  );
};

export default HostProfileStepOne;
