// TODO: recheck (wherever getHostAPIURL is used) secured+public APIs from this file; change in FE and in BE, use ops or planner_app API wherever feasible

import {
  HOST_API_BASE_URL,
  OPS_API_BASE_URL,
  PLANNER_API_BASE_URL
} from '@/services/connections.service';

const interpolatePath = ({ pathname, pathParams }) =>
  Object.entries(pathParams).reduce(
    (template, [key, value]) => template.replace(`:${key}`, value ?? ''),
    pathname
  );

const buildQueryString = ({ queryParams }) => {
  const queryString = new URLSearchParams(queryParams).toString();
  return queryString ? `?${queryString}` : '';
};

export const getURLPath = ({ pathname, pathParams = {}, queryParams = {} }) => {
  const interpolatedPath = interpolatePath({ pathname, pathParams });
  const queryString = buildQueryString({ queryParams });
  return `${interpolatedPath}${queryString}`;
};

const getAPIURL = ({
  baseUrl,
  pathname,
  pathParams = {},
  queryParams = {}
}) => {
  const path = getURLPath({ pathname, pathParams, queryParams });
  const url = new URL(`${baseUrl}${path}`);
  return url.toString();
};

const getOpsAPIURL = (pathname, pathParams = {}) =>
  getAPIURL({ baseUrl: OPS_API_BASE_URL, pathname, pathParams });

const getPlannerAPIURL = (pathname, pathParams = {}) =>
  getAPIURL({ baseUrl: PLANNER_API_BASE_URL, pathname, pathParams });

const getHostAPIURL = (pathname, pathParams = {}) =>
  getAPIURL({ baseUrl: HOST_API_BASE_URL, pathname, pathParams });

export const GET_CITY_DETAILS = getHostAPIURL('/cities');

export const GET_EVENT_SITE_TYPE_LIST = getHostAPIURL('/event-site-types');

export const GET_CHECKOUT_EVENT_LOOKUP = getHostAPIURL('/checkout/eventType');

export const GET_LEAD_EXIT_REASONS = getOpsAPIURL('/lead-exit-reasons');

export const GET_CONTACT_METHODS = getOpsAPIURL('/contact-methods');

export const GET_EVENT_VERTICALS = getOpsAPIURL('/event-verticals');

export const CREATE_TENDER_AND_DISPATCH_BIDS = () => getOpsAPIURL('/tenders');

export const POST_METABASE_DASHBOARD_SIGNED_URL = () =>
  getOpsAPIURL('/mb/generate-signed-url');

export const GET_TENDER_RESPONSE = ({ referenceId }) =>
  getOpsAPIURL('/tenders/responses/:referenceId', { referenceId });

export const GET_READ_ONLY_CART_DETAILS = ({ cartId }) =>
  getOpsAPIURL('/carts/:cartId/read-only-details', {
    cartId
  });

export const GET_ORDER_INVOICE = ({ orderId }) =>
  getOpsAPIURL('/orders/:orderId/invoice', { orderId });

export const GET_ORDER_DETAILS = ({ orderId }) =>
  getPlannerAPIURL('/orders/:orderId', {
    orderId
  });

export const UPDATE_USER_EVENT = (userId, userEventId) =>
  getHostAPIURL(
    '/users/:userId/user-events/:userEventId/update-user-event-profile',
    {
      userId,
      userEventId
    }
  );

export const GET_USER_EVENT_CART_DETAILS = ({ userEventId }) =>
  getPlannerAPIURL('/user-events/:userEventId/event-profile-and-cart-list', {
    userEventId
  });

export const GET_ORDER_MEDIA_BY_EVENT_ID = ({ userEventId }) =>
  getPlannerAPIURL('/user-events/:userEventId/orderMedia', {
    userEventId
  });

export const UPDATE_ARCHIVED_CART_STATUS = ({ userCartId, cartId }) =>
  getOpsAPIURL('/users/:userCartId/carts/:cartId/isArchived', {
    cartId,
    userCartId
  });

export const UPDATE_CART_INFO = ({ userCartId, cartId }) =>
  getHostAPIURL('/users/:userCartId/carts/:cartId', { userCartId, cartId });

export const CLONE_NON_ORDERED_CART = ({ userCartId, cartId }) =>
  getOpsAPIURL('/users/:userCartId/carts/:cartId/clone', {
    userCartId,
    cartId
  });

export const CLONE_ORDERED_CART = ({ userCartId, cartId }) =>
  getOpsAPIURL('/users/:userCartId/carts/:cartId/clone-ordered-cart', {
    userCartId,
    cartId
  });

export const REVISE_TENDER_AND_DISPATCH_BIDS = ({ referenceId }) =>
  getOpsAPIURL('/tenders/revise/:referenceId', { referenceId });

export const GET_CART_DETAILS = ({ cartId }) =>
  getPlannerAPIURL('/carts/:cartId/details', { cartId });

export const DELETE_ITEM_FROM_CART = ({ cartId, cartItemId, userCartId }) =>
  getOpsAPIURL('/users/:userCartId/carts/:cartId/cart-item/:cartItemId', {
    cartId,
    cartItemId,
    userCartId
  });

export const CLONE_CART_ITEM = ({ userCartId, cartId, cartItemId }) =>
  getOpsAPIURL('/users/:userCartId/carts/:cartId/cart-item/:cartItemId/clone', {
    userCartId,
    cartId,
    cartItemId
  });

export const UPDATE_CART_ITEM = ({ userCartId, cartId, cartItemId }) =>
  getHostAPIURL('/users/:userCartId/carts/:cartId/cart-item/:cartItemId', {
    userCartId,
    cartId,
    cartItemId
  });

export const DELETE_CART_ITEM_MEDIA = ({
  cartId,
  cartItemId,
  cartItemMediaId
}) =>
  getOpsAPIURL(
    '/cart/:cartId/cart-item/:cartItemId/cart-item-media/:cartItemMediaId',
    { cartId, cartItemId, cartItemMediaId }
  );

export const UPDATE_CART_ITEM_SHOW_PRODUCT_MEDIA = ({
  cartId,
  cartItemId,
  userCartId
}) =>
  getOpsAPIURL(
    '/users/:userCartId/carts/:cartId/cart-item/:cartItemId/showProductMedia',
    {
      cartId,
      cartItemId,
      userCartId
    }
  );

export const RECONCILE_CART_ITEM_MEDIA_FROM_IMAGEKIT = () =>
  getOpsAPIURL('/cart-item-media/reconcile');

export const GET_IMAGEKIT_AUTH = () => getOpsAPIURL('/imagekit/auth');

export const UPDATE_CART_ITEM_SORT_ORDER = ({ cartId }) =>
  getOpsAPIURL('/cart/:cartId/cart-item/update-sort-order', { cartId });

export const UPDATE_CART_ITEM_MEDIA_SORT_ORDER = ({ cartId, cartItemId }) =>
  getOpsAPIURL(
    '/cart/:cartId/cart-item/:cartItemId/cart-item-media/update-sort-order',
    { cartId, cartItemId }
  );

export const ADD_NEW_CART = ({ userId }) =>
  getOpsAPIURL('/users/:userId/cart', { userId });

export const GET_CART_QUOTATION = ({ userCartId, cartId }) =>
  getHostAPIURL('/user/:userCartId/carts/:cartId/quote-pdf', {
    userCartId,
    cartId
  });

export const EVENT_CART_CHECKOUT = ({ userCartId, cartId }) =>
  getOpsAPIURL('/users/:userCartId/carts/:cartId/checkout-non-default-cart', {
    userCartId,
    cartId
  });

export const ADMIN_GOOGLE_LOGIN_URL = () =>
  getOpsAPIURL('/auth/google?state=admin');

export const VERIFY_CART_NUMBER_FOR_ADD_ITEM = ({ cartNumber }) =>
  getPlannerAPIURL('/carts/:cartNumber/verify-cart-by-number-for-add-item', {
    cartNumber
  });

export const ADD_TO_CART = ({ cartId, userCartId }) =>
  getOpsAPIURL(`/users/${userCartId}/carts/${cartId}/cart-item`, {
    cartId,
    userCartId
  });

export const GET_HOST_PROFILE_AND_USER_EVENTS = ({ userId }) =>
  getPlannerAPIURL('/users/:userId/host-event-list', { userId });

export const GET_HOST_LIST = ({ filters, limit, skip }) =>
  getOpsAPIURL('/customers?skip=:skip&limit=:limit&filters=:filters', {
    filters,
    limit,
    skip
  });

export const ADD_HOST = () => getOpsAPIURL('/customers');

export const UPDATE_HOST = ({ hostId }) =>
  getOpsAPIURL('/customers/:customerId', { customerId: hostId });

export const GET_HOST_PROFILE_WITH_STATS = ({ hostId }) =>
  getPlannerAPIURL('/users/:hostId', { hostId });

export const VERIFY_CART_NUMBER_EXISTENCE_API = ({ cartNumber }) =>
  getPlannerAPIURL('/carts/:cartNumber/verify-if-exists-by-cart-number', {
    cartNumber
  });

export const CLONE_CART_TO_USER_EVENT_API = () =>
  getPlannerAPIURL('/carts/clone-cart-to-user-event');

export const GET_CUSTOMER_SEGMENTS = () => getOpsAPIURL('/customer-segments');

export const VERIFY_EVENT_NUMBER_EXISTENCE_API = ({ userEventNumber }) =>
  getPlannerAPIURL('/user-event/:userEventNumber/verify-if-exists-by-number', {
    userEventNumber
  });
export const GET_LANGUAGES = () => getOpsAPIURL('/languages');

export const GET_ETHNICITIES = () => getOpsAPIURL('/ethnicities');

export const DELETE_ORDER_ITEM_MEDIA = ({
  orderId,
  orderItemId,
  orderItemMediaId
}) =>
  getPlannerAPIURL(
    '/order/:orderId/order-item/:orderItemId/order-item-media/:orderItemMediaId',
    { orderId, orderItemId, orderItemMediaId }
  );

export const RECONCILE_ORDER_ITEM_MEDIA_FROM_IMAGEKIT = () =>
  getPlannerAPIURL('/order-item-media/reconcile');

export const UPDATE_ORDER_ITEM_MEDIA_SORT_ORDER = ({ orderId, orderItemId }) =>
  getPlannerAPIURL(
    '/order/:orderId/order-item/:orderItemId/order-item-media/update-sort-order',
    { orderId, orderItemId }
  );
