import { INVOICE_QUOTE_PDF_TYPE } from '@/config/common';
import { paymentDetailBody } from '@/config/quoteAndInvoice';
import { formatTimeToAmPm } from '@/lib/time';
import { getTermsAndConditionsURL } from '@/services/hostPortal.service';

const getOrderItemsBody = ({ lineItems, isStandardTypeSelected }) =>
  lineItems
    .map((element, index) => ({ lineItemNumber: index + 1, ...element }))
    .reduce(
      (
        accumulator,
        {
          lineItemNumber,
          listedPrice,
          name,
          productNotes,
          quantity,
          unitListedPrice
        }
      ) => {
        const productNotesText = productNotes ? `${productNotes}` : ' ';
        const lineItemContent = [
          lineItemNumber,
          name,
          quantity,
          unitListedPrice,
          listedPrice
        ];
        if (isStandardTypeSelected === false) {
          lineItemContent.splice(3, 1);
        }
        return [
          ...accumulator,
          lineItemContent,
          [' ', productNotesText, ' ', ' ', ' ']
        ];
      },
      []
    );

const getUserProfileBody = ({
  entity: {
    documentId,
    logisticsSummary: {
      cartName,
      deliveryDate,
      deliveryTime,
      eventAddress,
      eventContactEmail,
      eventContactName,
      eventDateTime,
      opsUserName,
      pickupDate,
      pickupTime,
      trnNumber,
      userMobileNumber
    },
    orderSummary
  },
  invoiceQuotePDFType,
  isProforma
}) => {
  const isInvoice = invoiceQuotePDFType === INVOICE_QUOTE_PDF_TYPE.INVOICE;
  const documentTitle =
    isProforma || isInvoice ? 'Invoice Number' : 'Quote Number';
  return [
    ['Customer Name', eventContactName, documentTitle, documentId],
    ['TRN', trnNumber, 'Issue Date & Time', orderSummary.issueDateTime],
    ['Phone No', userMobileNumber, 'Event Date & Time', eventDateTime],
    [
      'Delivery Date & Time',
      `${deliveryDate} ${deliveryTime ? formatTimeToAmPm(deliveryTime) : ''}`,
      'Pickup Date & Time',
      `${pickupDate} ${pickupTime ? formatTimeToAmPm(pickupTime) : ''}`
    ],
    ['Quote Name', cartName, 'Event Planner', opsUserName],
    [
      'Email ID',
      eventContactEmail,
      'Booking Date',
      `${invoiceQuotePDFType === INVOICE_QUOTE_PDF_TYPE.QUOTE ? '' : orderSummary.createdAt}`
    ],
    ['Delivery Address', eventAddress]
  ];
};

const getPaymentConfirmationDetailBody = ({ paymentConfirmationDetails }) => {
  if (paymentConfirmationDetails?.confirmationId) {
    return [[`Payment ID : ${paymentConfirmationDetails.confirmationId}`]];
  }
  return [[`${paymentConfirmationDetails.status}`]];
};

const getTemplateHeads = ({
  companyName,
  invoiceQuotePDFType,
  isProforma,
  isStandardTypeSelected
}) => {
  const isInvoice = invoiceQuotePDFType === INVOICE_QUOTE_PDF_TYPE.INVOICE;
  const invoiceQuotePDFTitle = isInvoice ? 'TAX INVOICE' : 'QUOTATION';
  const title = isProforma ? 'PROFORMA INVOICE' : invoiceQuotePDFTitle;

  const userProfileHeadTitlePrefix =
    isProforma || isInvoice
      ? `Invoice for${companyName ? ':' : ''}`
      : `Quotation for${companyName ? ':' : ''}`;

  const defaultHeads = {
    title,
    userProfileHead: [[userProfileHeadTitlePrefix, companyName, '', '']],
    orderItemsHead: [['#', 'Description', 'Quantity', 'Unit Price', 'Total']],
    paymentTermHead: [['Payment Terms', '', '']],
    paymentDetailHead: [['Payment Details']],
    generalTncHead: [['Terms & Conditions']],
    additionalNotesHead: [['Additional notes']]
  };

  if (isInvoice) {
    defaultHeads.paymentConfirmationDetailHead = [
      ['Payment Confirmation Details']
    ];
  }
  if (
    isStandardTypeSelected === false &&
    invoiceQuotePDFType === INVOICE_QUOTE_PDF_TYPE.QUOTE
  ) {
    defaultHeads.orderItemsHead = [['#', 'Description', 'Quantity', 'Total']];
  }
  return defaultHeads;
};

export const getPDFTemplateData = ({
  entity = {},
  invoiceQuotePDFType,
  isProforma
}) => {
  const UNICODE_CHAR_BULLET = '\u2022';

  const {
    isStandardTypeSelected,
    logisticsSummary: { companyName }
  } = entity;
  const invoiceQuotationHeads = getTemplateHeads({
    companyName,
    invoiceQuotePDFType,
    isProforma,
    isStandardTypeSelected
  });
  const additionalNotesBody = [[`${entity.orderSummary?.externalNotes}`]];

  const getOrderSummaryBody = ({
    additionalChargeAmount,
    deliveryChargeAmount,
    listedDiscountAmount,
    listedPriceAmount,
    lumpSumDiscount,
    nonTaxableCharge,
    orderTotal,
    preVatAmount,
    promoCodeDiscountAmount,
    sellingPriceAmount,
    vatAmount
  }) => [
    ...[
      [listedPriceAmount, 'Sub-total'],
      [listedDiscountAmount, 'Discounts'],
      [sellingPriceAmount, 'Item-Total'],
      [deliveryChargeAmount, 'Delivery Charges'],
      [additionalChargeAmount, 'Additional Charges'],
      [promoCodeDiscountAmount, 'Promo Discount'],
      [lumpSumDiscount, 'Bulk Discount'],
      [preVatAmount, 'Pre-VAT Total'],
      [vatAmount, 'VAT (5%)'],
      [nonTaxableCharge, 'Other Charges (Non-taxable)'],
      [orderTotal, 'Grand Total (AED)']
    ]
      .filter(([value]) => parseFloat(value) > 0)
      .map(([value, label]) => ['', '', '', label, value])
  ];

  const getPaymentTermsBody = () => {
    const paymentTermsInvoice = [
      ['Payment Received', `${entity.paymentTerms?.netPaymentReceived}`, ''],
      ['Payment Due', entity.paymentTerms?.paymentDueAmount, '']
    ];
    const paymentTermsQuote = [
      ['Payment Received', '0.00', ''],
      [
        'Payment Due',
        entity.paymentTerms?.paymentDueAmount,
        isProforma ? '' : 'Upon Confirmation'
      ]
    ];
    return invoiceQuotePDFType === INVOICE_QUOTE_PDF_TYPE.INVOICE
      ? paymentTermsInvoice
      : paymentTermsQuote;
  };

  const getGeneralTncBody = () => [
    [
      `${UNICODE_CHAR_BULLET} Items are subject to availability upon confirmation`
    ],
    [
      `${UNICODE_CHAR_BULLET} 100% advance payment is mandatory to block the order`,
      ''
    ],
    [
      `${UNICODE_CHAR_BULLET} All work permits, permissions and passes have to be arranged and provided by the client prior to the installation date to avoid any delay`,
      ''
    ],
    [
      `${UNICODE_CHAR_BULLET} If no security deposit has been added to the quote, the cost of replacement is payable by the renter in case of any loss/damage`,
      ''
    ],
    [
      `${UNICODE_CHAR_BULLET} For detailed terms & conditions, please refer ${getTermsAndConditionsURL()}`,
      ''
    ]
  ];

  return {
    ...invoiceQuotationHeads,
    additionalNotesBody,
    generalTncBody: getGeneralTncBody(),
    paymentDetailBody,
    paymentTermsBody: getPaymentTermsBody(),
    orderItemsBody: getOrderItemsBody(entity),
    orderSummaryBody: getOrderSummaryBody(entity.orderSummary),
    paymentConfirmationDetailBody: getPaymentConfirmationDetailBody(entity),
    userProfileBody: getUserProfileBody({
      entity,
      isProforma,
      invoiceQuotePDFType
    })
  };
};

export const customRenderingOfOrderItemRowCell = ({ row, cell, doc }) => {
  doc.setDrawColor(0, 0, 0);
  doc.setLineWidth(0.1);
  if (row.index % 2 === 1) {
    doc.line(
      cell.x,
      cell.y + cell.height,
      cell.x + cell.width,
      cell.y + cell.height
    );
  }
  doc.line(cell.x, cell.y + cell.height, cell.x, cell.y);
  doc.line(
    cell.x + cell.width,
    cell.y,
    cell.x + cell.width,
    cell.y + cell.height
  );
};
