import _ from 'lodash';

import {
  ALLOCATION_STATUS,
  CART_STATUS,
  EVENT_REQUEST_TYPE,
  MANDATORY_FIELDS_FOR_EPIC_ENHANCED,
  MANDATORY_FIELDS_FOR_EPIC_GENERATED,
  USER_EVENT_STAGE,
  VERIFICATION_STATUSES,
  ZENDESK_PRIORITY
} from 'config/common';
import copyToClipBoard from 'lib/copyToClipBoard';
import { getSerializedRichText } from 'lib/editor';
import {
  convertToShortMonthDateFormat,
  formatDateToHaflaStandardFormat
} from 'lib/time';
import * as HttpService from 'services/http.service';
import { createTenderAndPublishBids } from 'services/tender.service';
import {
  ADD_NEW_CART,
  CLONE_CART_TO_USER_EVENT_API,
  CLONE_NON_ORDERED_CART,
  CLONE_ORDERED_CART,
  GET_ORDER_MEDIA_BY_EVENT_ID,
  GET_USER_EVENT_CART_DETAILS,
  UPDATE_ARCHIVED_CART_STATUS,
  UPDATE_CART_INFO,
  VERIFY_CART_NUMBER_EXISTENCE_API,
  VERIFY_EVENT_NUMBER_EXISTENCE_API
} from 'services/url.service';

const DUMMY_EVENT_DETAILS = {
  checkoutEventId: null,
  companyName: null,
  contactMethodId: null,
  eventContactEmail: 'haflacustomer',
  eventContactName: 'ZD_AUTO_CUST',
  eventDate: '31/12/2099',
  eventDurationInDays: null,
  eventSiteTypeId: null,
  eventStartTime: '24:00',
  eventTitle: null,
  eventVerticals: [],
  expectedGuestCount: null,
  formattedAddress: 'Nook Coworking - Dubai - United Arab Emirates',
  opportunityValue: null,
  requesterType: null,
  theme: null,
  triageSummary: '',
  zendeskTicketId: null
};

const listOfHostTypeWithAdditionalRequirements = [
  'Corporate Generic',
  'Event Planning Company',
  'Government Organization',
  'Large Corporate',
  'Large Event Agency'
];

export const getUserEventCartDetails = ({ userEventId }) =>
  HttpService.getWithAuth(GET_USER_EVENT_CART_DETAILS({ userEventId }));

export const getOrderMediaByEventId = ({ userEventId }) =>
  HttpService.getWithAuth(GET_ORDER_MEDIA_BY_EVENT_ID({ userEventId }));

export const updateCartInfo = ({ userCartId, cartId, data }) =>
  HttpService.patchWithAuth(UPDATE_CART_INFO({ userCartId, cartId }), data);

const cloneNonOrderedCart = ({ userCartId, cartId, data }) =>
  HttpService.postWithAuth(
    CLONE_NON_ORDERED_CART({ userCartId, cartId }),
    data
  );

const cloneOrderedCart = ({ userCartId, cartId, data }) =>
  HttpService.postWithAuth(CLONE_ORDERED_CART({ userCartId, cartId }), data);

const updateArchivedStatus = ({ userCartId, cartId, isArchived }) =>
  HttpService.patchWithAuth(
    UPDATE_ARCHIVED_CART_STATUS({ userCartId, cartId }),
    { isArchived }
  );

export const addNewCart = (userId, data) =>
  HttpService.postWithAuth(ADD_NEW_CART({ userId }), data);

export const verifyCartNumberExistenceAPI = ({ cartNumber }) =>
  HttpService.getWithAuth(VERIFY_CART_NUMBER_EXISTENCE_API({ cartNumber }));

export const cloneCartToUserEventAPI = ({ cartNumber, userEventNumber }) =>
  HttpService.postWithAuth(CLONE_CART_TO_USER_EVENT_API(), {
    cartNumber,
    userEventNumber
  });

export const verifyEventNumberExistenceAPI = ({ userEventNumber }) =>
  HttpService.getWithAuth(
    VERIFY_EVENT_NUMBER_EXISTENCE_API({ userEventNumber })
  );

export const checkIsEmptyOrDummyEventDetail = ({ key, value }) => {
  const dummyValue = _.get(DUMMY_EVENT_DETAILS, key, 'key-not-found');
  if (_.isNil(value)) {
    return true;
  }

  if (dummyValue === 'key-not-found') {
    return false;
  }

  if (_.isNumber(value)) {
    return dummyValue === value;
  }

  if (_.isString(value) && _.isString(dummyValue)) {
    if (value?.trim() === '') {
      return true;
    }

    if (dummyValue?.trim() !== '') {
      return value.includes(dummyValue);
    }

    return false;
  }

  if (!_.isBoolean(value) && _.isEmpty(value)) {
    return true;
  }

  return false;
};

export const fetchOrderMediaList = async ({ userEventId }) => {
  const { entity, status, message } = await getOrderMediaByEventId({
    userEventId
  });
  if (status) {
    if (!_.isEmpty(entity?.orders)) {
      const userEventMediaList = entity.orders
        .map(({ orderMedia }) => orderMedia)
        .flat();
      return userEventMediaList;
    }
    return [];
  }
  return Promise.reject(new Error(message));
};

const fetchStaticAPIs = async ({
  setStaticEventCartDetails,
  setShowToast,
  userEventId
}) => {
  try {
    const [userEventMediaList] = await Promise.all([
      fetchOrderMediaList({ userEventId })
    ]);
    setStaticEventCartDetails({
      userEventMediaList
    });
  } catch (error) {
    setShowToast({
      show: true,
      status: false,
      message: error.message || 'Error while fetching static API Info'
    });
  }
};

export const fetchEventCartDetailsOnPageLoad = async ({
  setLoading,
  setShowToast,
  setStaticEventCartDetails,
  setUserEventCartDetails,
  userEventId
}) => {
  setLoading(true);
  const { status, entity, message } = await getUserEventCartDetails({
    userEventId
  });
  if (status && entity.userEventInformation) {
    setUserEventCartDetails(entity);
  }
  await fetchStaticAPIs({
    setStaticEventCartDetails,
    setShowToast,
    userEventId
  });
  setLoading(false);
  (!status || !entity.userEventInformation) &&
    setShowToast({ show: true, status, message });
};

export const fetchEventCartDetails = async ({
  setLoading,
  setShowToast,
  setUserEventCartDetails,
  userEventId
}) => {
  setLoading(true);
  const { status, entity, message } = await getUserEventCartDetails({
    userEventId
  });
  if (status && entity.userEventInformation) {
    setUserEventCartDetails(entity);
  }
  setLoading(false);
  (!status || !entity.userEventInformation) &&
    setShowToast({ show: true, status, message });
};

export const fetchAndSetCartDetails = async ({
  setShowToast,
  setLoading,
  setUserEventCartDetails,
  userEventId
}) => {
  setLoading(true);
  const {
    status,
    entity: { userEventCartList },
    message
  } = await getUserEventCartDetails({
    userEventId
  });
  if (status) {
    setUserEventCartDetails((prevState) => ({
      ...prevState,
      userEventCartList
    }));
  }
  setLoading(false);
  !status && setShowToast({ show: true, status, message });
};

export const invokeArchiveCart = async ({
  cartId,
  isArchived,
  setLoading,
  setShowToast,
  setUserEventCartDetails,
  userEventId,
  userId
}) => {
  setLoading(true);
  try {
    const { status, message } = await updateArchivedStatus({
      userCartId: userId,
      cartId,
      isArchived
    });
    status &&
      fetchAndSetCartDetails({
        setLoading,
        setShowToast,
        setUserEventCartDetails,
        userEventId
      });
    !status && setShowToast({ show: true, status, message });
  } catch (err) {
    setShowToast({
      show: true,
      status: false,
      message: err.response?.data?.message || 'API Failed'
    });
  }
  setLoading(false);
};

export const cloneCartAPI = async ({
  cartId,
  cartStatus,
  setLoading,
  setShowToast,
  setUserEventCartDetails,
  userEventId,
  userId
}) => {
  setLoading(true);
  const cloneCart =
    cartStatus === CART_STATUS.ORDER.value
      ? cloneOrderedCart
      : cloneNonOrderedCart;

  const { status, message } = await cloneCart({
    cartId,
    userCartId: userId
  });

  status &&
    fetchAndSetCartDetails({
      setLoading,
      setShowToast,
      setUserEventCartDetails,
      userEventId
    });
  setShowToast({ show: true, status, message });
  setLoading(false);
};

const blockScreenCreateTenderAndDispatchBids = async ({
  data,
  firstTender = false,
  setLoading,
  setShowToast,
  setUserEventCartDetails,
  userEventId
}) => {
  setLoading(true);
  const { status, message } = await createTenderAndPublishBids({
    data,
    firstTender
  });
  if (status) {
    await fetchAndSetCartDetails({
      setLoading,
      setShowToast,
      setUserEventCartDetails,
      userEventId
    });
  }
  setShowToast({
    message: message ?? message[0],
    show: true,
    status
  });
  setLoading(false);
};

export const createNewTenderAndPublishBids = async ({
  cartId,
  firstTender,
  setLoading,
  setShowToast,
  setUserEventCartDetails,
  userEventId
}) => {
  setLoading(true);
  await blockScreenCreateTenderAndDispatchBids({
    data: {
      cartId
    },
    firstTender,
    setLoading,
    setShowToast,
    setUserEventCartDetails,
    userEventId
  });
  setLoading(false);
};

export const createNewCart = async ({
  setLoading,
  setShowToast,
  setUserEventCartDetails,
  userEventCartDetails
}) => {
  const {
    userEventDetails: [{ eventContactName = '', userEventId } = {}] = [],
    userId
  } = userEventCartDetails.userEventInformation;
  const cartNamePrefix = eventContactName ? `${eventContactName}'s ` : '';
  const cartName = `${cartNamePrefix}Cart-${Date.now()}`;

  setLoading(true);
  const { status, message } = await addNewCart(userId, {
    userEventId,
    cartName
  });
  if (status) {
    await fetchAndSetCartDetails({
      setLoading,
      setShowToast,
      setUserEventCartDetails,
      userEventId
    });
  }
  setShowToast({ show: true, status, message });
  setLoading(false);
};

export const updateCart = async ({
  setLoading,
  setShowToast,
  setUserEventCartDetails,
  updateCartDetail,
  userId,
  userEventId
}) => {
  setLoading(true);
  try {
    const { status, message } = await updateCartInfo({
      userCartId: userId,
      cartId: updateCartDetail.data.id,
      data: updateCartDetail.data
    });

    if (status) {
      await fetchAndSetCartDetails({
        setLoading,
        setShowToast,
        setUserEventCartDetails,
        userEventId
      });
    }
    setShowToast({ show: true, status, message });
  } catch (error) {
    setShowToast({ show: true, status: false, message: error.message });
  }
  setLoading(false);
};

const countIncompleteEPICGeneratedFields = ({
  user,
  userEventDetails: [eventDetail],
  zendeskTicketId
}) => {
  const {
    checkoutEventId,
    eventContactName,
    eventDate,
    eventVerticals,
    expectedGuestCount,
    triageSummary
  } = eventDetail;
  const { customerSegment } = user || {};
  const requesterType = customerSegment?.name || null;

  const requiredEventDetailKeyValueList = [
    {
      key: MANDATORY_FIELDS_FOR_EPIC_GENERATED.EVENT_DATE,
      value: eventDate ? formatDateToHaflaStandardFormat(eventDate) : null
    },
    {
      key: MANDATORY_FIELDS_FOR_EPIC_GENERATED.EVENT_TYPE,
      value: checkoutEventId
    },
    {
      key: MANDATORY_FIELDS_FOR_EPIC_GENERATED.EVENT_VERTICALS,
      value: eventVerticals
    },
    {
      key: MANDATORY_FIELDS_FOR_EPIC_GENERATED.EXPECTED_GUEST_COUNT,
      value: expectedGuestCount
    },
    {
      key: MANDATORY_FIELDS_FOR_EPIC_GENERATED.REQUESTER_TYPE,
      value: requesterType
    },
    {
      key: MANDATORY_FIELDS_FOR_EPIC_GENERATED.REQUESTER_NAME,
      value: eventContactName
    },

    {
      key: MANDATORY_FIELDS_FOR_EPIC_GENERATED.TRIAGE_SUMMARY,
      value: getSerializedRichText(triageSummary)
    },
    {
      key: MANDATORY_FIELDS_FOR_EPIC_GENERATED.ZENDESK_TICKET_ID,
      value: zendeskTicketId
    }
  ];

  return requiredEventDetailKeyValueList.filter(checkIsEmptyOrDummyEventDetail)
    .length;
};

export const validateMandatoryFieldsForEPICEnhanced = ({
  contactMethodId,
  eventDurationInDays,
  user,
  userEventDetails: [eventDetail],
  zendeskTicketId
}) => {
  const { companyName, customerSegment } = user || {};
  const requesterType = customerSegment?.name || null;
  const {
    eventAddress,
    eventContactEmail,
    eventSiteTypeId,
    eventStartTime,
    eventTitle,
    opportunityValue,
    theme
  } = eventDetail;

  const formattedAddress =
    typeof eventAddress === 'string'
      ? eventAddress
      : eventAddress?.formattedAddress;

  const requiredFieldsToGoInEPICEnhancedStage = [
    {
      key: MANDATORY_FIELDS_FOR_EPIC_ENHANCED.CONTACT_METHOD_ID,
      value: contactMethodId
    },
    {
      key: MANDATORY_FIELDS_FOR_EPIC_ENHANCED.EVENT_DURATION_IN_DAYS,
      value: eventDurationInDays
    },
    {
      key: MANDATORY_FIELDS_FOR_EPIC_ENHANCED.EVENT_SITE_TYPE_ID,
      value: eventSiteTypeId
    },
    {
      key: MANDATORY_FIELDS_FOR_EPIC_ENHANCED.EVENT_START_TIME,
      value: eventStartTime
    },
    { key: MANDATORY_FIELDS_FOR_EPIC_ENHANCED.EVENT_TITLE, value: eventTitle },
    {
      key: MANDATORY_FIELDS_FOR_EPIC_ENHANCED.FORMATTED_ADDRESS,
      value: formattedAddress
    },
    {
      key: MANDATORY_FIELDS_FOR_EPIC_ENHANCED.OPPORTUNITY_VALUE,
      value: opportunityValue
    },
    { key: MANDATORY_FIELDS_FOR_EPIC_ENHANCED.THEME, value: theme },
    {
      key: MANDATORY_FIELDS_FOR_EPIC_ENHANCED.ZENDESK_TICKET_ID,
      value: zendeskTicketId
    }
  ];

  const enableAdditionalRequirements =
    listOfHostTypeWithAdditionalRequirements.includes(requesterType);

  const additionalRequiredFieldsBasedOnHostType = [
    { key: 'eventContactEmail', value: eventContactEmail },
    { key: 'companyName', value: companyName }
  ];

  const requiredFieldsToGoInEPICEnhancedStageBasedOnHostType =
    enableAdditionalRequirements ? additionalRequiredFieldsBasedOnHostType : [];

  const allRequiredFieldsToGoInEPICEnhancedStage = [
    ...requiredFieldsToGoInEPICEnhancedStage,
    ...requiredFieldsToGoInEPICEnhancedStageBasedOnHostType
  ];

  const anyMandatoryFieldWithDummyValue =
    allRequiredFieldsToGoInEPICEnhancedStage.some(
      checkIsEmptyOrDummyEventDetail
    );

  return !anyMandatoryFieldWithDummyValue;
};

export const getEPICGenrationCompletionPercentage = (userEvent) => {
  const countOfMandatoryFieldsForEPICGenerated = Object.keys(
    MANDATORY_FIELDS_FOR_EPIC_GENERATED
  ).length;

  const incompleteFieldsCount = countIncompleteEPICGeneratedFields(userEvent);

  return parseFloat(
    ((countOfMandatoryFieldsForEPICGenerated - incompleteFieldsCount) * 100) /
      countOfMandatoryFieldsForEPICGenerated
  ).toFixed(2);
};

export const validateMandatoryFieldsForEPICGenerated = (userEvent) =>
  countIncompleteEPICGeneratedFields(userEvent) === 0;

export const verifyCartNumberExistence = async ({
  inputValue: cartNumber,
  setInvalidMessage: setInvalidCartNumberMessage,
  setTargetEntity: setVerifiedCartDetail,
  setVerificationStatus: setCartVerificationStatus
}) => {
  const { FAILED, VERIFIED } = VERIFICATION_STATUSES;
  const { status, entity } = await verifyCartNumberExistenceAPI({
    cartNumber
  });
  if (status && entity.cartExists) {
    setVerifiedCartDetail({ ...entity.cart, cartNumber });
    setCartVerificationStatus(VERIFIED);
  } else {
    const { error: { errorMessage } = {} } = entity;
    const apiErrorMessage = errorMessage
      ? 'Cart status Invalid'
      : 'Cart number Invalid.';
    setCartVerificationStatus(FAILED);
    setInvalidCartNumberMessage(apiErrorMessage);
  }
};

export const cloneCartToUserEvent = async ({
  cartNumber,
  closeActionModal,
  setInvalidMessage,
  setLoading,
  setShowToast,
  setUserEventCartDetails,
  shouldFetchEventCartDetails = false,
  userEventId,
  userEventNumber
}) => {
  const { status, message } = await cloneCartToUserEventAPI({
    cartNumber,
    userEventNumber
  });
  if (status) {
    if (shouldFetchEventCartDetails) {
      await fetchEventCartDetails({
        setLoading,
        setShowToast,
        setUserEventCartDetails,
        userEventId
      });
    }
    closeActionModal();
    setShowToast({ show: true, status, message });
  } else {
    setInvalidMessage(message);
  }
};

export const verifyEventNumberExistence = async ({
  inputValue: userEventNumber,
  setInvalidMessage: setInvalidEventNumberMessage,
  setTargetEntity: setVerifiedEventDetail,
  setVerificationStatus: setEventVerificationStatus
}) => {
  const { FAILED, VERIFIED } = VERIFICATION_STATUSES;
  const { status, entity } = await verifyEventNumberExistenceAPI({
    userEventNumber
  });

  if (status && entity.userEventExists) {
    const {
      userEvent: {
        userEventDetails: [
          { eventTitle = '', checkoutEvent: { event: { name } = {} } = {} } = {}
        ] = []
      }
    } = entity;
    const eventName = eventTitle || name;
    setVerifiedEventDetail({ ...entity.userEvent, eventName, userEventNumber });
    setEventVerificationStatus(VERIFIED);
  } else {
    setEventVerificationStatus(FAILED);
    setInvalidEventNumberMessage('Event number Invalid.');
  }
};

export const getLabelForEventRequestIsEndToEnd = (eventRequestIsEndToEnd) =>
  eventRequestIsEndToEnd
    ? EVENT_REQUEST_TYPE.END_TO_END
    : EVENT_REQUEST_TYPE.PRODUCT_AND_SERVICE_ONLY;

export const onSubmitCartChanges = async ({
  allocationStatus,
  setCartStatusChangeValidationError,
  status: targetCartStatus,
  updateCartDetailOnSave,
  userEventInformation,
  ...rest
}) => {
  const allowCartChanges = () => {
    if (allocationStatus === ALLOCATION_STATUS.EVENT_ACCEPTED.value) {
      return (
        validateMandatoryFieldsForEPICGenerated(userEventInformation) &&
        validateMandatoryFieldsForEPICEnhanced(userEventInformation)
      );
    }
    return false;
  };

  if (targetCartStatus === CART_STATUS.COLLABORATE.value) {
    if (!allowCartChanges()) {
      setCartStatusChangeValidationError(true);
      return null;
    }
  }

  return updateCartDetailOnSave({ status: targetCartStatus, ...rest });
};

export const createCartWarningRequiredByEventStage = ({ stage }) =>
  [
    USER_EVENT_STAGE.EPIC_GENERATED.key,
    USER_EVENT_STAGE.EVENT_ACCEPTED.key,
    USER_EVENT_STAGE.EVENT_ASSIGNED.key
  ].includes(stage);

export const getZendeskTicketPriorityConfig = (priority) => {
  const configForOldTicketWherePriorityIsMissing = {
    className: 'expired rounded-lg border border-error-red',
    icon: 'red-status-icon.svg',
    value: 'NA'
  };
  return (
    Object.values(ZENDESK_PRIORITY).find(({ value }) => value === priority) ??
    configForOldTicketWherePriorityIsMissing
  );
};

export const parseUserEventInfoForEPIC = ({ key, placeholderValue, value }) =>
  checkIsEmptyOrDummyEventDetail({ key, value })
    ? placeholderValue
    : `${value}`;

export const getEPICExplanationStringInput = ({
  epicParameters: {
    city,
    eventDate,
    eventType,
    guests,
    hostName,
    hostType,
    verticals
  }
}) => [
  {
    prefix: '',
    rank: 1, // TODO: Sidd to revisit, once feature is stable remove key
    value: parseUserEventInfoForEPIC({
      key: 'eventContactName',
      placeholderValue: '{Host Name}',
      value: hostName
    })
  },
  {
    key: 'requesterType',
    prefix: ', a(n) ',
    rank: 2,
    value: parseUserEventInfoForEPIC({
      key: 'requesterType',
      placeholderValue: '{Host Type}',
      value: hostType
    })
  },
  {
    prefix: ', is planning a ',
    rank: 3,
    value: parseUserEventInfoForEPIC({
      key: 'eventType',
      placeholderValue: '{Event Type}',
      value: eventType
    })
  },
  {
    prefix: ' on ',
    rank: 4,
    value: parseUserEventInfoForEPIC({
      key: 'eventDate',
      placeholderValue: '{Event Date}',
      value: eventDate ? convertToShortMonthDateFormat(eventDate) : null
    })
  },
  {
    prefix: ' for ',
    rank: 5,
    value: parseUserEventInfoForEPIC({
      key: 'expectedGuestCount',
      placeholderValue: '{No. of guests}',
      value: guests
    })
  },
  {
    prefix: ' guests in ',
    rank: 6,
    value: parseUserEventInfoForEPIC({
      key: 'cityId',
      placeholderValue: '{City}',
      value: city
    })
  },
  {
    rank: 7,
    prefix: ' and is looking for ',
    value: parseUserEventInfoForEPIC({
      key: 'eventVerticals',
      placeholderValue: '{Hafla Request Vertical(s)}',
      value:
        verticals.length > 0
          ? verticals.map(({ name }) => name).join(', ')
          : verticals
    })
  }
];

export const copyToClipboardEPICGeneation = async ({
  epicParameters,
  setShowToast
}) => {
  const isClipboardAvailable = Boolean(navigator.clipboard);
  if (isClipboardAvailable) {
    const epicGenerationMessage = getEPICExplanationStringInput({
      epicParameters
    })
      .map(({ prefix, value }) => `${prefix}${value}`)
      .join('');

    await copyToClipBoard(epicGenerationMessage);
  }
  setShowToast({
    message: isClipboardAvailable
      ? 'Successfully copied to clipboard'
      : 'Failed to copy to clipboard',
    show: true,
    status: isClipboardAvailable
  });
};
