import {
  convertToCurrencyFormat,
  parseFormatPriceValueFromAPI
} from '@/lib/numberStringUtils';
import { convertToMonthYearFormat } from '@/lib/time';
import * as HttpService from '@/services/http.service';
import {
  GET_CHECKOUT_EVENT_LOOKUP,
  GET_CITY_DETAILS,
  GET_CONTACT_METHODS,
  GET_EVENT_SITE_TYPE_LIST,
  GET_EVENT_VERTICALS,
  GET_HOST_PROFILE_AND_USER_EVENTS,
  GET_LEAD_EXIT_REASONS,
  GET_USER_EVENT_CART_DETAILS,
  UPDATE_USER_EVENT
} from '@/services/url.service';

const getHostProfileAndUserEvents = ({ userId }) =>
  HttpService.getWithAuth(GET_HOST_PROFILE_AND_USER_EVENTS({ userId }));

export const getUserEventCartDetails = ({ userEventId }) =>
  HttpService.getWithAuth(GET_USER_EVENT_CART_DETAILS({ userEventId }));

export const getEventSiteTypeList = () =>
  HttpService.getWithAuth(GET_EVENT_SITE_TYPE_LIST);

export const getCityDetails = () =>
  HttpService.getWithoutAuth(GET_CITY_DETAILS);

export const getLeadExitReasons = () =>
  HttpService.getWithAuth(GET_LEAD_EXIT_REASONS);

export const getContactMethods = () =>
  HttpService.getWithAuth(GET_CONTACT_METHODS);

export const getEventVerticals = () =>
  HttpService.getWithAuth(GET_EVENT_VERTICALS);

export const getCheckoutEventLookup = () =>
  HttpService.getWithoutAuth(GET_CHECKOUT_EVENT_LOOKUP);

export const updateUserEvent = ({ userId, userEventId, userEventDetail }) =>
  HttpService.postWithAuth(
    UPDATE_USER_EVENT(userId, userEventId),
    userEventDetail
  );

export const userEventStageBasedClass = [
  {
    className: 'in-progress',
    label: 'Event Accepted'
  },
  {
    className: 'in-progress',
    label: 'Deeper Understanding'
  },
  {
    className: 'new',
    label: 'Solution Building'
  },
  {
    className: 'new',
    label: 'Cart Complete'
  },
  {
    className: 'submitted',
    label: 'Order Booked'
  },
  {
    className: 'submitted',
    label: 'Order Placed'
  },
  {
    className: 'expired',
    label: 'Not Converted'
  }
];

export const getHostEventStatisticsParamaters = ({
  createdAt,
  hostEventStatistics: {
    userEventCount,
    userEventsAEV,
    userEventsConverted,
    userEventsGEV
  }
} = {}) => ({
  createdAt: convertToMonthYearFormat({ dateTimeString: createdAt }),
  userEventCount: {
    label: 'Events Inquired',
    value: userEventCount
  },
  userEventsConverted: {
    alt: 'book-event-icon',
    icon: 'book-event-fill-icon.svg',
    label: 'Booked Events',
    value: userEventsConverted
  },
  userEventsGEV: {
    alt: 'event-gev-icon',
    icon: 'gev-fill-icon.svg',
    label: 'GEV',
    value: convertToCurrencyFormat({
      value: parseFormatPriceValueFromAPI(userEventsGEV),
      withCurrencyPrefix: false
    })
  },
  userEventsAEV: {
    alt: 'event-gev-icon',
    icon: 'gev-fill-icon.svg',
    label: 'AEV',
    value: convertToCurrencyFormat({
      value: parseFormatPriceValueFromAPI(userEventsAEV),
      withCurrencyPrefix: false
    })
  }
});

export const getHostTouchPoints = ({ email, mobile, requesterType }) => [
  {
    alt: 'call',
    className: 'gap-2 mt-6',
    icon: 'call-with-frame.svg',
    iconHeight: 24,
    iconWidth: 24,
    label: mobile,
    labelStyle: 'text-dim-gray font-medium',
    show: Boolean(mobile)
  },
  {
    alt: 'user',
    className: 'gap-2 mt-3',
    icon: 'user-red-with-frame.svg',
    iconHeight: 24,
    iconWidth: 24,
    label: requesterType,
    labelStyle: 'text-dim-gray font-medium',
    show: Boolean(requesterType)
  },
  {
    alt: 'email',
    className: 'gap-2 mt-3',
    icon: 'email-icon.svg',
    iconHeight: 24,
    iconWidth: 24,
    label: email,
    labelStyle: 'max-w-[23rem] truncate font-medium text-dim-gray',
    show: Boolean(email)
  }
];

export const getStatusBasedOptionsForUserEventCard = ({ isPastEvent }) => {
  let dictionary;
  switch (isPastEvent) {
    case true:
      dictionary = {
        dateIcon: 'calendar-gray-with-frame.svg',
        eventIcon: 'event-gray-icon.svg',
        eventStatusSpecificClassName: 'expired-event',
        imgStyle: 'opacity-50',
        locationIcon: 'location-icon-gray.svg',
        userIcon: 'planner-icon-gray.svg',
        zendeskIcon: 'zendesk-gray-icon.svg'
      };
      break;
    default:
      dictionary = {
        dateIcon: 'calendar-red-with-frame.svg',
        eventIcon: 'event-red-icon.svg',
        locationIcon: 'location-icon-with-frame.svg',
        userIcon: 'planner-icon-orange.svg',
        zendeskIcon: 'zendesk-icon.svg'
      };
      break;
  }
  return dictionary;
};

export const fetchHostProfileAndUserEvents = async ({
  hostId,
  setHostProfileAndUserEvents,
  setLoading,
  setShowToast
}) => {
  setLoading(true);
  const { status, entity, message } = await getHostProfileAndUserEvents({
    userId: hostId
  });
  const isUserPresent = status && entity.user;
  if (isUserPresent) {
    setHostProfileAndUserEvents(entity);
  } else {
    setShowToast({ show: true, status, message });
  }
  setLoading(false);
};

export const EVENT_PROFILE_FORM_STEPS = {
  BASIC_EVENT_PROFILE: {
    id: '#basic-user-event-profile',
    label: 'Event Profile'
  },
  HOST_PROFILE: {
    id: '#host-profile',
    label: 'Host Profile'
  },
  EVENT_DEEP_DIVE: {
    id: '#event-deep-dive',
    label: 'Event Deep Dive'
  },
  TERMINATE: {
    id: '#terminate-user-event',
    label: 'Terminate Event'
  }
};

export const eventProfileStepFieldErrorConfig = {
  [EVENT_PROFILE_FORM_STEPS.BASIC_EVENT_PROFILE.id]: [
    'checkoutEventId',
    'eventDate'
  ],
  [EVENT_PROFILE_FORM_STEPS.EVENT_DEEP_DIVE.id]: [
    'budget',
    'eventDurationInDays',
    'eventTitle',
    'expectedGuestCount',
    'opportunityValue'
  ],
  [EVENT_PROFILE_FORM_STEPS.HOST_PROFILE.id]: [
    'eventContactEmail',
    'eventContactName'
  ],
  [EVENT_PROFILE_FORM_STEPS.TERMINATE.id]: ['leadExitReasonId']
};

export const checkStepHasErrors = ({ fieldsErrorConfig, errors, stepId }) =>
  Boolean(fieldsErrorConfig[stepId].find((field) => errors[field]));

const fetchCities = async () => {
  const { entity: cityList, status, message } = await getCityDetails();
  if (status) {
    return cityList;
  }

  return Promise.reject(new Error(message));
};

const fetchEventSiteTypes = async () => {
  const {
    entity: eventSiteTypeList,
    status,
    message
  } = await getEventSiteTypeList();
  if (status) {
    return eventSiteTypeList;
  }

  return Promise.reject(new Error(message));
};

const fetchContactMethods = async () => {
  const { entity: contactMethods, status, message } = await getContactMethods();
  if (status) {
    return contactMethods;
  }

  return Promise.reject(new Error(message));
};

const fetchEventVerticals = async () => {
  const { entity: eventVerticals, status, message } = await getEventVerticals();
  if (status) {
    return eventVerticals;
  }

  return Promise.reject(new Error(message));
};

const fetchEventTypes = async () => {
  const { status, entity, message } = await getCheckoutEventLookup();
  if (status && entity) {
    const checkoutEvents = entity.map((events) => ({
      id: events.id,
      name: events.event.name
    }));
    return checkoutEvents;
  }

  return Promise.reject(new Error(message));
};

const fetchLeadExitReasons = async () => {
  const {
    entity: leadExitReasonsFromAPI,
    status,
    message
  } = await getLeadExitReasons();
  if (status && leadExitReasonsFromAPI) {
    const leadExitReasons = leadExitReasonsFromAPI.filter(
      ({ name }) => name !== 'Reason Unknown'
    );
    return leadExitReasons;
  }

  return Promise.reject(new Error(message));
};

const fetchStaticAPIs = async ({
  setStaticUserEventInformation,
  setShowToast
}) => {
  try {
    const [
      cityList,
      eventSiteTypeList,
      checkoutEvents,
      contactMethods,
      eventVerticals,
      leadExitReasons
    ] = await Promise.all([
      fetchCities(),
      fetchEventSiteTypes(),
      fetchEventTypes(),
      fetchContactMethods(),
      fetchEventVerticals(),
      fetchLeadExitReasons()
    ]);
    setStaticUserEventInformation({
      checkoutEvents,
      cityList,
      contactMethods,
      eventSiteTypeList,
      eventVerticals,
      leadExitReasons
    });
  } catch (error) {
    setShowToast({
      show: true,
      status: false,
      message: error.message || 'Error while fetching static API Info'
    });
  }
};

export const fetchUserEventInformationOnPageLoad = async ({
  setLoading,
  setShowToast,
  setStaticUserEventInformation,
  setUserEventInformation,
  userEventId
}) => {
  setLoading(true);
  const { status, entity, message } = await getUserEventCartDetails({
    userEventId
  });
  const { userEventInformation } = entity || {};
  if (status && userEventInformation) {
    setUserEventInformation(userEventInformation);
  }
  await fetchStaticAPIs({
    setStaticUserEventInformation,
    setShowToast
  });
  setLoading(false);
  (!status || !userEventInformation) &&
    setShowToast({ show: true, status, message });
};

export const submitUserEventProfile = async ({
  data,
  eventFormDetails,
  reset,
  selectedEventRequestVerticals,
  setError,
  setEventFormDetails,
  setFormActiveStepId,
  setUserEventDetailEditMode,
  setUserEventInformation,
  setValue,
  userInfo
}) => {
  const selectedEventType = eventFormDetails.checkoutEvents.find(
    (type) => type.id === data.checkoutEventId
  );

  const source = 'ProfileForm';

  const { status, entity, message } = await updateUserEvent({
    userId: userInfo.id,
    userEventId: data.userEventId,
    userEventDetail: {
      ...data,
      contactMethodId: eventFormDetails.selectedEventContactMethod,
      eventAddress: eventFormDetails.userAddress,
      eventId: selectedEventType?.id || '',
      eventName: selectedEventType?.name || '',
      eventSiteTypeId: eventFormDetails.selectedEventSiteType,
      eventStartTime: eventFormDetails.selectedEventStartTime,
      eventTypeName: selectedEventType?.name || '',
      eventVerticalIds: selectedEventRequestVerticals,
      source,
      userId: userInfo.id,
      zendeskTicketId: eventFormDetails.zendeskTicketId
    }
  });

  const zendeskTicketDetailDefault = {
    assigneeName: null,
    assigneeEmail: null,
    createdAt: null,
    haflaRequestVertical: null,
    inquiryGenerationStatus: null,
    inquiryNumber: null,
    inquiryToQuoteStatus: null,
    priority: null,
    quoteNumber: null,
    quoteToClosureStatus: null,
    requesterType: null,
    submissionDate: null,
    totalGEVQuoted: null
  };

  if (status && entity) {
    setUserEventInformation(entity);
    const { userEventDetails: [{ eventTitle = '' } = {}] = [] } = entity;
    setEventFormDetails((prev) => ({
      ...prev,
      userEventId: entity?.id
    }));
    reset({
      ...data,
      eventTitle
    });
    const zendeskTicketDetail =
      entity?.zendeskTicketDetail || zendeskTicketDetailDefault;
    Object.keys(zendeskTicketDetail).forEach((zendeskDetailKey) =>
      setValue(zendeskDetailKey, zendeskTicketDetail[zendeskDetailKey])
    );
    setValue('leadExitReasonId', entity.leadExitReasonId);
    setEventFormDetails((prev) => ({
      ...prev,
      toastStatus: true,
      toastMessage: 'Event Details Added successfully.'
    }));
    setUserEventDetailEditMode(false);
    return;
  }
  if (message) {
    setError('leadExitReasonId', { type: 'custom', message });
    setFormActiveStepId(EVENT_PROFILE_FORM_STEPS.TERMINATE.id);
    return;
  }
  setEventFormDetails((prev) => ({
    ...prev,
    toastStatus: false,
    toastMessage:
      'Oops, something went wrong. Failed to create a user event. ' +
      'Please use the ops portal to check if this Zendesk ticket is not ' +
      'already attached to another event profile.'
  }));
};
